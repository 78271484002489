import Color from "./color";
import Size from "./size";

interface sizeInfo {
  size: string;
  sizeObject?: Size;
  amount: number;
  allocatedAmount?: number;
}

interface InventoryProduct {
  productId?: number;
  codeIdentifier?: string;
  name?: string;
  colorId?: number;
  color?: string;
  colorObject?: Color;
  totalAmount?: number;
  totalAllocatedAmount?: number;
  hasMissingProducts?: boolean;
  sizes?: sizeInfo[];
}

export interface InventoryProductLog {
  id?: number;
  amount?: number;
  amountPreviousToOperation?: number;
  subject?: string;
  obs?: string;
  clothingProductId?: number;
  productVariantId?: number;
  productCodeIdentifier?: string;
  productName?: string;
  colorName?: string;
  sizeName?: string;
  inventoryName?: string;
  createdAt?: string;
}

interface ClientInfo {
  clientName: string;
  pendingAmount: number;
}

export interface PendingAmounts {
  sizeId?: number;
  size?: string;
  clients?: ClientInfo[];
}

export const newInventoryProduct = (): InventoryProduct => {
  return {};
};

export const MINIMUNAMOUNTPERSIZE = 20;
export const MINIMUNAMOUNTPERSIZESTORE = 5;

export default InventoryProduct;
