import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
  CBadge,
  CSelect,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getItem, getList } from "../../api/generics";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { successAlert, warningAlert } from "../utils/messages";
import StoreExpenseUpdateModal from "./StoreExpenseUpdateModal";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import {
  STORE_EXPENSES_CREATE,
  STORE_EXPENSES_UPDATE,
} from "../../auth/permissions";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import StoreExpense, {
  getStoreExpenseStatusClass,
  getStoreExpenseStatusLabel,
  getStoreExpenseTypeLabel,
  STOREEXPENSESTATUSES,
  STOREEXPENSETYPES,
} from "../../models/store-expense";
import { formatDateTime } from "../../utils/dates";
import { defaultValueOnUndefined } from "../../utils/fields";
import { formatToCurrency } from "../../currency/format";
import { PYG } from "../../currency/available-currencies";
import UserSelect from "../user/UserSelect";
import SingleStoreSelect from "../store/SingleStoreSelect";
import Store from "../../models/store";
import User from "../../models/user";
import generateStoreExpensesReportPDF from "../../utils/storeExpensesPdfGenerator";

const ITEMS_PER_PAGE = 30;
const ITEMS_PER_PAGE_FOR_REPORT = -1; // Basically No limit

const StoreExpenseList = () => {
  const fields = [
    {
      key: "expenseDate",
      _classes: ["text-center"],
      label: "Fecha",
    },
    {
      key: "storeName",
      _classes: ["text-center"],
      label: "Sucursal",
    },
    {
      key: "userName",
      _classes: ["text-center"],
      label: "Usuario",
    },
    {
      key: "expenseType",
      _classes: ["text-center"],
      label: "Tipo",
    },
    {
      key: "description",
      _classes: ["text-center"],
      label: "Descripción",
    },
    {
      key: "price",
      _classes: ["text-center"],
      label: "Monto",
    },
    {
      key: "status",
      _classes: ["text-center"],
      label: "Estado",
    },
    {
      key: "revertDescription",
      _classes: ["text-center"],
      label: "Motivo Reversión",
    },
    {
      key: "revertDate",
      _classes: ["text-center"],
      label: "Fecha Reversión",
    },
    {
      key: "revertUserName",
      _classes: ["text-center"],
      label: "Usuario Reversión",
    },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const [showRevertModal, setShowRevertModal] = useState(false);

  const [storeExpense, setStoreExpense] = useState<StoreExpense | undefined>(
    undefined
  );
  const [search, setSearch] = useState<string>("");
  const [startDate, setStartDate] = useState<string | moment.Moment>("");
  const [endDate, setEndDate] = useState<string | moment.Moment>("");
  const [filterType, setFilterType] = useState<string>("");
  const [filterStatus, setFilterStatus] = useState<string>("");
  const [filterStore, setFilterStore] = useState<Store | undefined>(undefined);
  const [filterUser, setFilterUser] = useState<User | undefined>(undefined);

  const [loading, setLoading] = useState(true);
  const [storeExpenses, setStoreExpenses] = useState<StoreExpense[]>([]);
  const [storeExpensesReport, setStoreExpensesReport] = useState<
    StoreExpense[]
  >([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [enableReportButton, setEnableReportButton] = useState(false);
  const [queryTotalPrice, setQueryTotalPrice] = useState<number | undefined>(
    undefined
  );
  const [pageTotalPrice, setPageTotalPrice] = useState<number | undefined>(0);
  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let expenseStatus = urlParams.get("expense_status")
      ? urlParams.get("expense_status")
      : "";
    let expenseType = urlParams.get("expense_type")
      ? urlParams.get("expense_type")
      : "";
    let storeId = urlParams.get("store_id") ? urlParams.get("store_id") : "";
    let userId = urlParams.get("user_id") ? urlParams.get("user_id") : "";
    let startDate = urlParams.get("start_date")
      ? urlParams.get("start_date")
      : "";
    let endDate = urlParams.get("end_date") ? urlParams.get("end_date") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    if (expenseStatus !== "") map.set("expense_status", expenseStatus);
    if (expenseType !== "") map.set("expense_type", expenseType);
    if (startDate !== "") map.set("start_date", startDate);
    if (endDate !== "") map.set("end_date", endDate);
    if (storeId !== "") map.set("store_id", storeId);
    if (userId !== "") map.set("user_id", userId);
    return map;
  };

  const fetchStoreExpenses = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const requestStatus = await getList<StoreExpense>(
      "/store_expenses/",
      limit,
      offset,
      urlParams
    );
    if (requestStatus.status === SUCCESS) {
      if (requestStatus.data !== undefined) {
        const count = requestStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setPageTotalPrice(calculatePageTotal(requestStatus.data.items));
        setStoreExpenses(requestStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = requestStatus.detail
        ? requestStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const fetchTotals = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    urlParams.set("limit", limit);
    urlParams.set("offset", offset);
    const totalsStatus = await getItem<{
      fullTotal?: number;
    }>("/store_expenses/totals/", urlParams);
    if (totalsStatus.status === SUCCESS) {
      if (totalsStatus.data !== undefined) {
        setQueryTotalPrice(totalsStatus.data.fullTotal);
      }
    } else {
      const message = totalsStatus.detail
        ? totalsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const calculatePageTotal = (sales: StoreExpense[]): number => {
    const total = sales
      .filter((sale) => !sale.reverted)
      .reduce((acc, sale) => {
        const price = sale.price ?? 0;
        return acc + price;
      }, 0);

    return total;
  };

  const fetchStoreExpensesReport = async () => {
    setEnableReportButton(false);
    const urlParams = getURLParams();
    urlParams.set("descending", "false");
    const limit = ITEMS_PER_PAGE_FOR_REPORT;
    const offset = 0;
    const storeExpensesStatus = await getList<StoreExpense>(
      "/store_expenses/",
      limit,
      offset,
      urlParams
    );
    if (storeExpensesStatus.status === SUCCESS) {
      if (storeExpensesStatus.data !== undefined) {
        setStoreExpensesReport(storeExpensesStatus.data.items);
        setEnableReportButton(true);
      }
    } else {
      setEnableReportButton(false);
    }
  };

  const onStartDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setStartDate("");
      return;
    }
    setStartDate(value);
  };

  const onEndDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setEndDate("");
      return;
    }
    setEndDate(value);
  };

  const onFilterTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterType(e.target.value);
  };

  const onFilterStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterStatus(e.target.value);
  };

  const onFilterStoreChange = (newStore: Store | null) => {
    setFilterStore(newStore !== null ? newStore : undefined);
  };

  const onFilterUserChange = (newUser: User | null) => {
    setFilterUser(newUser !== null ? newUser : undefined);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    if (previousParams.get("expense_status")) {
      urlParams.set("expense_status", previousParams.get("expense_status"));
    }
    if (previousParams.get("expense_type")) {
      urlParams.set("expense_type", previousParams.get("expense_type"));
    }
    if (previousParams.get("store_id")) {
      urlParams.set("store_id", previousParams.get("store_id"));
    }
    if (previousParams.get("user_id")) {
      urlParams.set("user_id", previousParams.get("user_id"));
    }
    if (previousParams.get("start_date")) {
      urlParams.set("start_date", previousParams.get("start_date"));
    }
    if (previousParams.get("end_date")) {
      urlParams.set("end_date", previousParams.get("end_date"));
    }
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onAddClick = () => {
    setShowRevertModal(true);
  };

  const onRevertClick = (storeExpense: StoreExpense) => {
    setStoreExpense(storeExpense);
    setShowRevertModal(true);
  };

  const onRevertCancel = () => {
    setStoreExpense(undefined);
    setShowRevertModal(false);
  };

  const onRevertSuccess = () => {
    successAlert("Datos guardados con éxito!");
    setShowRevertModal(false);
    setStoreExpenses([]);
    setLoading(true);
    fetchStoreExpenses();
  };

  const onReportClick = async () => {
    setEnableReportButton(false);
    if (storeExpensesReport.length <= 0) {
      warningAlert("No se han seleccionado registros");
      setEnableReportButton(true);
      return;
    }

    const urlParams = getURLParams();
    const titlesRight: string[] = generateTitlesRight(urlParams);

    const filteredTitlesRight = titlesRight.filter((title) => title !== "");

    await generateStoreExpensesReportPDF(
      storeExpensesReport,
      [
        "Reporte de Gastos",
        "",
        "Reporte generado el:",
        formatDateTime(new Date()),
      ],
      filteredTitlesRight
    );
    setEnableReportButton(true);
  };

  const generateTitlesRight = (map: Map<any, any>): string[] => {
    const titlesRight: string[] = ["Filtros Utilizados: "];

    map.forEach((value, key) => {
      const stringKey = typeof key === "string" ? key : "";
      const stringValue = typeof value === "string" ? value : "";
      const description = getFilterDescription(stringKey, stringValue);
      titlesRight.push(description);
    });

    return titlesRight;
  };

  const getFilterDescription = (key: string, value: string) => {
    switch (key) {
      case "search":
        if (value !== "") {
          return `Búsqueda: ${value}`;
        } else {
          return "";
        }
      case "start_date":
        return `Fecha de inicio: ${formatDateTime(new Date(value))}`;
      case "end_date":
        return `Fecha de fin: ${formatDateTime(new Date(value))}`;
      default:
        return "";
    }
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    if (search !== "") {
      urlParams.set("search", search);
    } else {
      urlParams.delete("search");
    }
    if (filterStatus !== "") {
      urlParams.set("expense_status", filterStatus);
    } else {
      urlParams.delete("expense_status");
    }
    if (filterType !== "") {
      urlParams.set("expense_type", filterType);
    } else {
      urlParams.delete("expense_type");
    }
    if (filterStore !== undefined && filterStore.id !== undefined) {
      urlParams.set("store_id", filterStore.id.toString());
    } else {
      urlParams.delete("store_id");
    }
    if (filterUser !== undefined && filterUser.id !== undefined) {
      urlParams.set("user_id", filterUser.id.toString());
    } else {
      urlParams.delete("user_id");
    }
    if (moment.isMoment(startDate)) {
      urlParams.set("start_date", startDate.toISOString());
    } else if (startDate !== undefined && startDate !== "") {
      urlParams.set("start_date", startDate);
    } else {
      urlParams.delete("start_date");
    }
    if (moment.isMoment(endDate)) {
      urlParams.set("end_date", endDate.toISOString());
    } else if (endDate !== undefined && endDate !== "") {
      urlParams.set("end_date", endDate);
    } else {
      urlParams.delete("end_date");
    }
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  useEffect(() => {
    fetchStoreExpenses();
    fetchStoreExpensesReport();
    fetchTotals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("storeExpensesList")) {
        return;
      }
      fetchStoreExpenses();
      fetchStoreExpensesReport();
      fetchTotals();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Gastos en Sucursal</h3>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent
                  permissionName={STORE_EXPENSES_CREATE}
                >
                  <CButton
                    color="primary"
                    className="float-right"
                    onClick={onAddClick}
                  >
                    <BsFillPlusCircleFill />
                    &nbsp; Agregar Nuevo
                  </CButton>
                </PermissionRequiredComponent>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CButton
                    color="danger"
                    className="fa-solid fa-file-pdf"
                    onClick={onReportClick}
                    disabled={!enableReportButton || loading}
                  ></CButton>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <h5>Filtros</h5>
                </CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Estado:</CLabel>
                </CCol>
                <CCol>
                  <CSelect
                    type="text"
                    defaultValue={filterStatus !== null ? filterStatus : ""}
                    onChange={onFilterStatusChange}
                  >
                    <option value={""}>-----</option>
                    {Object.entries(STOREEXPENSESTATUSES).map(
                      ([key, entry], ix) => {
                        return (
                          <option key={ix} value={key}>
                            {entry.name}
                          </option>
                        );
                      }
                    )}
                  </CSelect>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Tipo:</CLabel>
                </CCol>
                <CCol>
                  <CSelect
                    type="text"
                    defaultValue={filterType !== null ? filterType : ""}
                    onChange={onFilterTypeChange}
                  >
                    <option value={""}>-----</option>
                    {Object.entries(STOREEXPENSETYPES).map(
                      ([key, entry], ix) => {
                        return (
                          <option key={ix} value={key}>
                            {entry.name}
                          </option>
                        );
                      }
                    )}
                  </CSelect>
                </CCol>
              </CRow>
              <CRow>
                <CCol></CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Desde:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onStartDatehange}
                    value={startDate}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Hasta:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onEndDatehange}
                    value={endDate}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Sucursal:</CLabel>
                </CCol>
                <CCol>
                  <SingleStoreSelect
                    value={filterStore ? filterStore : null}
                    onChange={onFilterStoreChange}
                  ></SingleStoreSelect>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Usuario Venta:</CLabel>
                </CCol>
                <CCol>
                  <UserSelect
                    value={filterUser ? filterUser : null}
                    onChange={onFilterUserChange}
                  ></UserSelect>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Buscar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={storeExpenses}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    expenseDate: (item: StoreExpense) => {
                      return (
                        <td className="text-center">
                          {item.expenseDate
                            ? formatDateTime(new Date(item.expenseDate))
                            : "-"}
                        </td>
                      );
                    },
                    revertDate: (item: StoreExpense) => {
                      return (
                        <td className="text-center">
                          {item.revertDate
                            ? formatDateTime(new Date(item.revertDate))
                            : "-"}
                        </td>
                      );
                    },
                    storeName: (item: StoreExpense) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.storeName)}
                        </td>
                      );
                    },
                    revertDescription: (item: StoreExpense) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.revertDescription)}
                        </td>
                      );
                    },
                    price: (item: StoreExpense) => {
                      return (
                        <td className="text-center">
                          {formatToCurrency(item.price || 0, PYG)}
                        </td>
                      );
                    },
                    userName: (item: StoreExpense) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.userName)}
                        </td>
                      );
                    },
                    revertUserName: (item: StoreExpense) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.revertUserName)}
                        </td>
                      );
                    },
                    expenseType: (item: StoreExpense) => {
                      return (
                        <td className="text-center">
                          {item.expenseType
                            ? getStoreExpenseTypeLabel(item.expenseType)
                            : "-"}
                        </td>
                      );
                    },
                    status: (item: StoreExpense) => {
                      return (
                        <td className="text-center">
                          <CBadge
                            color={
                              item.status
                                ? getStoreExpenseStatusClass(item.status)
                                : undefined
                            }
                          >
                            {item.status
                              ? getStoreExpenseStatusLabel(item.status)
                              : undefined}
                          </CBadge>
                        </td>
                      );
                    },

                    actions: (item: StoreExpense) => {
                      const editButton = (
                        <PermissionRequiredComponent
                          permissionName={STORE_EXPENSES_UPDATE}
                        >
                          <CButton
                            className="text-white"
                            color="primary"
                            onClick={() => {
                              onRevertClick(item);
                            }}
                          >
                            <i className="fa fa-rotate-left"></i>
                          </CButton>
                        </PermissionRequiredComponent>
                      );

                      return (
                        <td className="text-right">
                          <CButtonGroup>
                            {!item.reverted ? editButton : <></>}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
              <CRow className={"mt-2"}>
                <CCol className={"border border-dark"}>
                  <CRow className={"mt-2"}>
                    <CCol>
                      <h5>Valores Totales de selección:</h5>
                    </CCol>
                  </CRow>
                  <CRow className={"mt-2"}>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Venta total de la pagina visualizada:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {pageTotalPrice
                          ? formatToCurrency(pageTotalPrice, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Venta total todas las páginas:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {queryTotalPrice
                          ? formatToCurrency(queryTotalPrice, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <p>
                        <small>
                          * Los valores de operaciones revertidas se omiten de
                          los totales
                        </small>
                      </p>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <StoreExpenseUpdateModal
        show={showRevertModal}
        onCancel={onRevertCancel}
        onSuccess={onRevertSuccess}
        storeExpense={storeExpense}
      />
    </>
  );
};

export default StoreExpenseList;
