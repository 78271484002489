import SaleOrderSummary from "../components/amounts-summary/SaleOrderSummary";

const SaleOrderSummaryPage = () => {
  return (
    <>
      <SaleOrderSummary />
    </>
  );
};

export default SaleOrderSummaryPage;
