import {
  CButton,
  CCol,
  CContainer,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getList, updateItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert, warningAlert } from "../utils/messages";
import ProductionOrder, {
  ProductionOrderSize,
} from "../../models/production-order";

import Errors, { getFieldErrors } from "../../models/errors";
import Variant, {
  VariantTableEntry,
  variantAPIToTable,
  variantTableFormatToAPIProductionOrderItem,
} from "../../models/variant";
import { emptyValueOnUndefined } from "../../utils/fields";
import { FieldErrors } from "../form/FieldErrors";

interface ProductionColorsModalProps {
  show: boolean;
  productionOrder?: ProductionOrder;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const FIXED_COLUMNS = ["Color"];

const ProductionColorsModal: React.FC<ProductionColorsModalProps> = ({
  show,
  productionOrder,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const [errors, setErrors] = useState<Errors>({});

  const [tableColumns, setTableColumns] = useState<string[]>(FIXED_COLUMNS);

  const [tableEntries, setTableEntries] = useState<VariantTableEntry[]>([]);
  const [assignedAmount, setAssignedAmount] = useState<number>(0);

  const message = productionOrder
    ? `Formulario de confección para proceso de producción #${productionOrder.id}, ${productionOrder.productCodeIdentifier} - ${productionOrder.productName}`
    : "";

  const getOrderSizes = async () => {
    if (productionOrder === undefined || productionOrder.id === undefined) {
      return [];
    }
    const productionOrderIdStr = productionOrder.id.toString();
    if (productionOrderIdStr === undefined) {
      return [];
    }
    const limit = 100;
    const offset = 0;
    const orderSizesStatus = await getList<ProductionOrderSize>(
      `/production/${productionOrderIdStr}/order_sizes/`,
      limit,
      offset
    );
    if (orderSizesStatus.status === SUCCESS) {
      if (orderSizesStatus.data !== undefined) {
        return orderSizesStatus.data.items;
      }
      return [];
    } else {
      return [];
    }
  };

  const fetchVariants = async () => {
    if (productionOrder?.productId === undefined) {
      setTableEntries([]);
      return;
    }
    const productIdStr = productionOrder.productId.toString();
    const limit = 100;
    const offset = 0;
    const additional = new Map();
    additional.set("clothing_product_id", productIdStr);

    try {
      const sizesStatus = await getList<Variant>(
        "/product_variants/with_related/",
        limit,
        offset,
        additional
      );

      if (sizesStatus.status === SUCCESS && sizesStatus.data !== undefined) {
        const cuttedSizes = await getOrderSizes();
        const filteredVariants = sizesStatus.data.items.filter((variant) => {
          return cuttedSizes.some(
            (orderSize) => orderSize.sizeId === variant.sizeId
          );
        });
        const formattedTableEntries = variantAPIToTable(filteredVariants);

        setTableEntries(formattedTableEntries);
        generateTableHeaders(formattedTableEntries);
      } else {
        setTableEntries([]);
      }
    } catch (error) {
      console.error("Error fetching variants:", error);

      setTableEntries([]);
    }
  };

  const onAmountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, variantId: number | undefined) => {
      const newAmountNumber = Number(e.target.value);

      if (variantId !== undefined) {
        const newTableEntries = tableEntries.map((entry) => {
          const updatedSizes = entry.sizes?.map((size) => {
            if (size.variantId === variantId) {
              return {
                ...size,
                amount: newAmountNumber,
              };
            }
            return size;
          });

          return {
            ...entry,
            sizes: updatedSizes,
          };
        });
        setTableEntries(newTableEntries);
        let totalAmount = 0;

        newTableEntries.forEach((entry) => {
          entry.sizes?.forEach((size) => {
            if (size.amount !== undefined) {
              totalAmount += size.amount;
            }
          });
        });

        setAssignedAmount(totalAmount);
      }
    },
    [tableEntries]
  );

  useEffect(() => {
    fetchVariants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionOrder]);

  const onSubmitClick = async () => {
    if (productionOrder !== undefined) {
      setSubmitting(true);

      let toSendItems = variantTableFormatToAPIProductionOrderItem(
        tableEntries,
        false,
        undefined
      );

      if (toSendItems.length <= 0) {
        warningAlert(
          "Se debe seleccionar al menos una tamaño y una cantidad cortada"
        );
        setSubmitting(false);
        return;
      }

      if (
        assignedAmount >
        (productionOrder.remainingAmountToDefine !== undefined
          ? productionOrder.remainingAmountToDefine
          : productionOrder.amountProduced!)
      ) {
        warningAlert("No puedes asignar mas unidades que las cortadas");
        setErrors({
          assignedAmount: [
            "el valor NO puede superar la cantidad restante de prendas cortadas",
          ],
        });
        setSubmitting(false);
        return;
      }

      toSendItems = toSendItems.map((item) => ({
        ...item,
        orderId: productionOrder.id,
        size: undefined,
        barcodeBatch: item.barcodeBatch + "P" + productionOrder.id,
      }));

      let toSendOrder: ProductionOrder = {
        ...productionOrder,
        orderItems: toSendItems,
        currentStageIsConfection: false,
        currentStageIsLaundry: true,
        currentStageAllowRelatedAmounts: true,
        remainingAmountToDefine:
          (productionOrder.remainingAmountToDefine !== undefined
            ? productionOrder.remainingAmountToDefine
            : productionOrder.amountProduced!) - assignedAmount,
      };

      const orderStatus = await updateItem<ProductionOrder>(
        `/production/${productionOrder.id}/advance/`,
        toSendOrder
      );

      if (orderStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (orderStatus.errors !== undefined) {
          setErrors(orderStatus.errors);
        }
        if (orderStatus.detail !== undefined) {
          message = orderStatus.detail;
        }
        errorAlert(message);
      } else {
        setErrors({});
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  const generateTableHeaders = (entries: VariantTableEntry[]) => {
    const uniqueSizeNames: string[] = [];

    entries.forEach((item) => {
      if (item.sizes) {
        item.sizes
          .map((size) => size?.sizeName)
          .filter(
            (sizeName) =>
              sizeName !== undefined && !uniqueSizeNames.includes(sizeName)
          )
          .forEach((sizeName) => {
            if (sizeName) {
              uniqueSizeNames.push(sizeName);
            }
          });
      }
    });

    uniqueSizeNames.sort((a, b) => {
      const aFloat = parseFloat(a);
      const bFloat = parseFloat(b);
      return aFloat - bFloat;
    });

    setTableColumns([...FIXED_COLUMNS, ...uniqueSizeNames]);
  };

  const fetchProductionOrderSizes = async () => {
    await fetchVariants();
  };

  useEffect(() => {
    setErrors({});
    setAssignedAmount(0);
    fetchProductionOrderSizes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionOrder]);

  return (
    <CModal show={show} className="modal-primary" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Formulario de Confección</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
              <p>
                Indique las cantidades confeccionadas por color para la
                lavanderia
              </p>
            </CCol>
          </CRow>
          <CFormGroup>
            <CRow className={"mb-2"}>
              <CCol md={3}>
                <CLabel>Cantidad Total cortada:</CLabel>
              </CCol>
              <CCol md={2}>
                <CInput
                  type="number"
                  disabled
                  value={productionOrder?.amountProduced}
                ></CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol md={3}>
                <CLabel>Cantidad cortada restante por asignar:</CLabel>
              </CCol>
              <CCol md={2}>
                <CInput
                  type="number"
                  disabled
                  value={productionOrder?.remainingAmountToDefine}
                ></CInput>
              </CCol>
              <CCol md={3} className={"offset-md-1"}>
                <CLabel>Cantidad asignada:</CLabel>
              </CCol>
              <CCol md={2}>
                <CInput
                  type="number"
                  disabled
                  value={assignedAmount}
                  min={0}
                ></CInput>
                <FieldErrors
                  errors={getFieldErrors("assignedAmount", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CRow>
            <CCol md={2}>
              <h5>Cantidades</h5>
            </CCol>
          </CRow>
          <CRow>
            <CCol md={12}>
              <div className="table-responsive">
                <table className="table table-striped table-bordered table-fixed">
                  <thead>
                    <tr>
                      {tableColumns.map((title, ix) => {
                        return (
                          <th
                            className="text-center"
                            key={ix}
                            style={{
                              verticalAlign: "middle",
                              overflow: "hidden",
                            }}
                          >
                            <div className="d-inline">{title}</div>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {tableEntries.map((tableEntry, ix) => {
                      return (
                        <tr
                          style={{
                            verticalAlign: "middle",
                            overflow: "hidden",
                          }}
                          key={ix}
                        >
                          <td
                            className={"text-center"}
                            style={{ padding: 0, width: "120px" }}
                          >
                            <p>{tableEntry.colorName}</p>
                          </td>
                          {tableEntry.sizes?.map((size, iy) => {
                            return (
                              <td
                                key={iy}
                                style={{ padding: 0, width: "70px" }}
                              >
                                <CInput
                                  type="number"
                                  value={emptyValueOnUndefined(size.amount)}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => onAmountChange(e, size.variantId)}
                                ></CInput>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                    {tableEntries.length === 0 ? (
                      <tr>
                        <td colSpan={tableColumns.length}>
                          No hay tamaños disponibles para esta prenda.
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
                <br />
                <br />
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onSubmitClick} color="primary">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Guardando..." : "Guardar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ProductionColorsModal;
