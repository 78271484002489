import InventoryCategorySummary from "../components/amounts-summary/InventoryCategorySummary";

const InventoryCategorySummaryPage = () => {
  return (
    <>
      <InventoryCategorySummary />
    </>
  );
};

export default InventoryCategorySummaryPage;
