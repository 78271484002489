interface StoreExpense {
  id?: number;
  companyId?: number;
  storeId?: number;
  storeName?: string;
  userId?: number;
  userName?: string;
  expenseDate?: string;
  expenseType?: string;
  price?: number;
  description?: string;
  status?: string;
  revertDescription?: string;
  revertDate?: string;
  revertUserId?: number;
  revertUserName?: string;
  reverted?: boolean;
}

export const newStoreExpense = (): StoreExpense => {
  return {};
};

export interface StatusData {
  class: string;
  name: string;
}

interface StatusesMap {
  [index: string]: StatusData;
}

export const STOREEXPENSESTATUSES: StatusesMap = {
  registered: {
    class: "success",
    name: "Registrado",
  },
  reverted: {
    class: "danger",
    name: "Revertido",
  },
};

export const getStoreExpenseStatusLabel = (key: string) => {
  return STOREEXPENSESTATUSES[key].name;
};

export const getStoreExpenseStatusClass = (key: string) => {
  return STOREEXPENSESTATUSES[key].class;
};

export const STOREEXPENSETYPES: StatusesMap = {
  in_store: {
    class: "info",
    name: "Registrado en tienda",
  },
  in_factory: {
    class: "info",
    name: "Registrado en fábrica",
  },
};

export const getStoreExpenseTypeLabel = (key: string) => {
  return STOREEXPENSETYPES[key].name;
};

export const getStoreExpenseTypeClass = (key: string) => {
  return STOREEXPENSETYPES[key].class;
};

export default StoreExpense;
