import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
  CSelect,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getItem, getList } from "../../api/generics";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { warningAlert } from "../utils/messages";

import "react-datetime/css/react-datetime.css";

import { formatToCurrency } from "../../currency/format";
import { PYG } from "../../currency/available-currencies";

import SingleStoreSelect from "../store/SingleStoreSelect";
import Store from "../../models/store";
import SoldSumaryEntry from "../../models/sold-summary";
import { formatDateTime } from "../../utils/dates";
import generateInventorySummaryPdf from "../../utils/inventorySummaryPdfGenerator";

const ITEMS_PER_PAGE = 1200;
const ITEMS_PER_PAGE_FOR_REPORT = -1; // Basically No limit

export const SUMMARYTYPES: { [key: string]: string } = {
  categories: "Categorías",
  products: "Productos",
};

export const OWNERTYPES: { [key: string]: string } = {
  donna: "DONNA",
  jeans: "JEANS",
};

const InventoryCategorySummary = () => {
  const fields = [
    {
      key: "counter",
      _classes: ["text-center"],
      label: "#",
    },
    {
      key: "name",
      _classes: ["text-center"],
      label: "Nombre",
    },
    {
      key: "amount",
      _classes: ["text-center"],
      label: "Cantidad",
    },
    {
      key: "soldPrice",
      _classes: ["text-center"],
      label: "Valor de venta",
    },
    /*{
      key: "profit",
      _classes: ["text-center"],
      label: "Ganancia estimada",
    },*/
  ];

  const [filterStore, setFilterStore] = useState<Store | undefined>(undefined);
  const [filterCategoryOwner, setFilterCategoryOwner] = useState<string>("");
  const [viewType, setViewType] = useState<string>("categories");
  const [search, setSearch] = useState<string>("");

  const [loading, setLoading] = useState(true);

  const [tableEntries, setTableEntries] = useState<SoldSumaryEntry[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const [pageTotalPrice, setPageTotalPrice] = useState<number | undefined>(0);
  const [pageTotalAmount, setPageTotalAmount] = useState<number | undefined>(0);

  const [enableReportButton, setEnableReportButton] = useState(false);
  const [entriesReport, setEntriesReport] = useState<SoldSumaryEntry[]>([]);

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let owner = urlParams.get("owner") ? urlParams.get("owner") : "";
    let view_products = urlParams.get("view") ? urlParams.get("view") : "";
    let storeId = urlParams.get("store_id") ? urlParams.get("store_id") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    if (view_products !== "") map.set("view", view_products);
    if (owner !== "") map.set("owner", owner);
    if (storeId !== "") map.set("store_id", storeId);
    return map;
  };

  const fetchEntries = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const summaryStatus = await getList<SoldSumaryEntry>(
      "/amounts_summary/inventory/",
      limit,
      offset,
      urlParams
    );
    if (summaryStatus.status === SUCCESS) {
      if (summaryStatus.data !== undefined) {
        const count = summaryStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setTableEntries(summaryStatus.data.items);
        setCurrentPage(urlParams.get("page"));
        setPageTotalPrice(calculatePageTotalPrice(summaryStatus.data.items));
        setPageTotalAmount(calculatePageTotalAmount(summaryStatus.data.items));
      }
    } else {
      const message = summaryStatus.detail
        ? summaryStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const fetchEntriesReport = async () => {
    setEnableReportButton(false);
    const urlParams = getURLParams();
    urlParams.set("descending", "false");
    const limit = ITEMS_PER_PAGE_FOR_REPORT;
    const offset = 0;
    const storeSalesStatus = await getList<SoldSumaryEntry>(
      "/amounts_summary/inventory/",
      limit,
      offset,
      urlParams
    );
    if (storeSalesStatus.status === SUCCESS) {
      if (storeSalesStatus.data !== undefined) {
        const filteredPerReport = storeSalesStatus.data.items.filter(
          (item) => item.amount !== undefined && item.amount != 0
        );
        setEntriesReport(filteredPerReport);
        setEnableReportButton(true);
      }
    } else {
      setEnableReportButton(false);
    }
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    if (previousParams.get("view")) {
      urlParams.set("view", previousParams.get("view"));
    }
    if (previousParams.get("owner")) {
      urlParams.set("owner", previousParams.get("owner"));
    }
    if (previousParams.get("store_id")) {
      urlParams.set("store_id", previousParams.get("store_id"));
    }
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onViewTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setViewType(e.target.value);
  };

  const onOwnerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterCategoryOwner(e.target.value);
  };

  const onFilterStoreChange = (newStore: Store | null) => {
    setFilterStore(newStore !== null ? newStore : undefined);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    if (filterCategoryOwner !== "") {
      urlParams.set("owner", filterCategoryOwner);
    } else {
      urlParams.delete("owner");
    }
    if (search !== "") {
      urlParams.set("search", search);
    } else {
      urlParams.delete("search");
    }
    if (viewType === "products") {
      urlParams.set("view", "products");
    } else if (viewType === "categories") {
      urlParams.set("view", "categories");
    } else {
      urlParams.delete("view");
    }
    if (filterStore !== undefined && filterStore.id !== undefined) {
      urlParams.set("store_id", filterStore.id.toString());
    } else {
      urlParams.delete("store_id");
    }
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const calculatePageTotalAmount = (entries: SoldSumaryEntry[]): number => {
    const total = entries.reduce((acc, entry) => {
      const amount = entry.amount ?? 0;
      return acc + amount;
    }, 0);

    return total;
  };

  const calculatePageTotalPrice = (entries: SoldSumaryEntry[]): number => {
    const total = entries.reduce((acc, entry) => {
      const price = entry.soldPrice ?? 0;
      return acc + price;
    }, 0);

    return total;
  };

  const onReportClick = async () => {
    setEnableReportButton(false);
    if (entriesReport.length <= 0) {
      warningAlert("No se han seleccionado ventas");
      setEnableReportButton(true);
      return;
    }

    const urlParams = getURLParams();

    let storeName = "";
    if (urlParams.get("store_id")) {
      storeName = await fetchStoreName(urlParams.get("store_id"));
    }
    const titlesRight: string[] = generateTitlesRight(urlParams);

    if (storeName !== "") {
      titlesRight.push(`Sucursal: ${storeName}`);
    }

    const filteredTitlesRight = titlesRight.filter((title) => title !== "");

    await generateInventorySummaryPdf(
      entriesReport,
      [
        "Reporte Inventario",
        "por categorias",
        "Reporte generado el:",
        formatDateTime(new Date()),
      ],
      filteredTitlesRight
    );
    setEnableReportButton(true);
  };

  const generateTitlesRight = (map: Map<any, any>): string[] => {
    const titlesRight: string[] = ["Filtros Utilizados: "];

    map.forEach((value, key) => {
      const stringKey = typeof key === "string" ? key : "";
      const stringValue = typeof value === "string" ? value : "";
      const description = getFilterDescription(stringKey, stringValue);
      titlesRight.push(description);
    });

    return titlesRight;
  };

  const getFilterDescription = (key: string, value: string) => {
    switch (key) {
      case "search":
        if (value !== "") {
          return `Búsqueda: ${value}`;
        } else {
          return "";
        }
      case "view":
        return `Tipo: ${value === "categories" ? "Categorias" : "Productos"}`;
      case "start_date":
        return `Fecha de inicio: ${formatDateTime(new Date(value))}`;
      case "end_date":
        return `Fecha de fin: ${formatDateTime(new Date(value))}`;
      default:
        return "";
    }
  };

  const fetchStoreName = async (id: string) => {
    if (id === "") {
      return id;
    }
    const storeStatus = await getItem<Store>(`/stores/${id}/`);
    if (storeStatus.status === SUCCESS) {
      if (storeStatus.data !== undefined) {
        return storeStatus.data.name || "";
      }
    }
    return "";
  };

  useEffect(() => {
    fetchEntries();
    fetchEntriesReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("inventoryCategorySummary")) {
        return;
      }
      fetchEntries();
      fetchEntriesReport();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Inventario por Categorias</h3>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CButton
                    color="danger"
                    className="fa-solid fa-file-pdf"
                    onClick={onReportClick}
                    disabled={!enableReportButton || loading}
                  ></CButton>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <h5>Filtros</h5>
                </CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Vizualizar:</CLabel>
                </CCol>
                <CCol>
                  <CSelect
                    type="text"
                    defaultValue={viewType !== null ? viewType : ""}
                    onChange={onViewTypeChange}
                  >
                    {Object.entries(SUMMARYTYPES).map(([key, entry], ix) => {
                      return (
                        <option key={ix} value={key}>
                          {entry}
                        </option>
                      );
                    })}
                  </CSelect>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Dueño:</CLabel>
                </CCol>
                <CCol>
                  <CSelect
                    type="text"
                    defaultValue={filterCategoryOwner}
                    onChange={onOwnerChange}
                  >
                    <option value={""}>-------</option>
                    {Object.entries(OWNERTYPES).map(([key, entry], ix) => {
                      return (
                        <option key={ix} value={key}>
                          {entry}
                        </option>
                      );
                    })}
                  </CSelect>
                </CCol>
              </CRow>
              <CRow>
                <CCol></CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Sucursal:</CLabel>
                </CCol>
                <CCol>
                  <SingleStoreSelect
                    value={filterStore ? filterStore : null}
                    onChange={onFilterStoreChange}
                  ></SingleStoreSelect>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda (factura/cliente)"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Filtrar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={tableEntries}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    soldPrice: (item: SoldSumaryEntry) => {
                      return (
                        <td className="text-center">
                          {formatToCurrency(item.soldPrice, PYG)}
                        </td>
                      );
                    },
                    profit: (item: SoldSumaryEntry) => {
                      return (
                        <td className="text-center">
                          {formatToCurrency(item.profit, PYG)}
                        </td>
                      );
                    },
                    counter: (_: SoldSumaryEntry, ix: number) => {
                      return <td className="text-center">{ix + 1}</td>;
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
              <CRow className={"mt-2"}>
                <CCol className={"border border-dark"}>
                  <CRow className={"mt-2"}>
                    <CCol>
                      <h5>Valores Totales de selección:</h5>
                    </CCol>
                  </CRow>
                  <CRow className={"mt-2"}>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Cantidad Total de artículos:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {pageTotalAmount ? `${pageTotalAmount} unidades` : "-"}
                      </CLabel>
                    </CCol>
                  </CRow>
                  <CRow className={"mt-2"}>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        Valor de Venta total:
                      </CLabel>
                    </CCol>
                    <CCol md={3}>
                      <CLabel className={"mt-2 font-weight-bold"}>
                        {pageTotalPrice
                          ? formatToCurrency(pageTotalPrice, PYG)
                          : "-"}
                      </CLabel>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default InventoryCategorySummary;
