import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  createItem,
  updateItem,
  ItemRequestStatus,
  getItem,
} from "../../api/generics";
import Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import Store from "../../models/store";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert, warningAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import InventoryLocation, {
  InventoryLocationValueLog,
} from "../../models/inventory-location";
import CurrencyField from "../currencies/CurrencyField";
import { PYG } from "../../currency/available-currencies";

interface StoreDebtLogFormProps {
  initialStore?: Store;
  initialInventoryLocation?: InventoryLocation;
  initialDebtLog?: InventoryLocationValueLog;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const StoreDebtLogForm: React.FC<StoreDebtLogFormProps> = ({
  initialStore,
  initialInventoryLocation,
  initialDebtLog,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);
  const [editingDebtLog, setEditingDebtLog] =
    useState<InventoryLocationValueLog>(
      initialDebtLog
        ? {
            ...initialDebtLog,
            amount: initialDebtLog.amount ? -initialDebtLog.amount : undefined,
          }
        : {}
    );

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);
  const [orderDate, setOrderDate] = useState<string | moment.Moment>("");

  const onOrderDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      return;
    }
    setOrderDate(value);
    if (moment.isMoment(value)) {
      setEditingDebtLog({
        ...editingDebtLog,
        date: value.toISOString(),
      });
    } else if (value !== undefined && value !== "") {
      setEditingDebtLog({
        ...editingDebtLog,
        date: value,
      });
    }
  };
  const onObsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingDebtLog({
      ...editingDebtLog,
      obs: e.target.value,
    });
  };

  const onAmountChange = (newPrice: number | undefined) => {
    setEditingDebtLog({
      ...editingDebtLog,
      amount: newPrice,
    });
  };

  const onAutoFillClick = () => {
    const newData: InventoryLocationValueLog = { ...editingDebtLog };

    newData.date = moment().toISOString();
    setOrderDate(moment());
    setEditingDebtLog(newData);
  };

  const onSave = async () => {
    setSubmitting(true);
    if (!initialStore || !initialInventoryLocation) {
      warningAlert("no se pudo recuperar los datos necesarios la tienda");
      setSubmitting(false);
      return;
    }
    let toSendDebtLog: InventoryLocationValueLog = {
      ...editingDebtLog,
      companyId: company.id,
      amount: editingDebtLog.amount ? -editingDebtLog.amount : undefined,
      storeName: initialStore.name,
      storeId: initialStore.id,
      locationId: initialInventoryLocation.id,
      manualEntry: true,
    };

    let requestPromise: Promise<ItemRequestStatus<Store>>;
    if (editingDebtLog.id === undefined) {
      const lastEntryStatus = await getItem<InventoryLocationValueLog>(
        `/inventory_locations/value_logs/last_log/${initialStore.id}/`
      );
      if (lastEntryStatus.status === SUCCESS) {
        if (lastEntryStatus.data !== undefined) {
          let lastLogValue = lastEntryStatus.data;
          toSendDebtLog.previousValue =
            (lastLogValue.previousValue ?? 0) + (lastLogValue.amount ?? 0);
        }
      } else {
        const message = lastEntryStatus.detail
          ? lastEntryStatus.detail
          : "Error desconocido";
        warningAlert(message);
        setSubmitting(false);
        return;
      }

      requestPromise = createItem<InventoryLocationValueLog>(
        "/inventory_locations/value_logs/create/",
        toSendDebtLog
      );
    } else {
      requestPromise = updateItem<InventoryLocationValueLog>(
        `/inventory_locations/value_logs/${toSendDebtLog.id}/`,
        toSendDebtLog
      );
    }

    const storeStatus = await requestPromise;

    if (storeStatus.status !== SUCCESS) {
      if (storeStatus.errors !== undefined) {
        setErrors(storeStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (storeStatus.detail !== undefined) {
        message = storeStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingDebtLog({});
    setOrderDate("");
  };

  useEffect(() => {
    const date = initialDebtLog?.date;
    setOrderDate(date ? moment(date) : "");
    setEditingDebtLog(
      initialDebtLog
        ? {
            ...initialDebtLog,
            amount: initialDebtLog.amount ? -initialDebtLog.amount : undefined,
          }
        : {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDebtLog]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel className={"mt-2"}>Fecha:</CLabel>
            </CCol>
            <CCol>
              <Datetime
                className="pl-0"
                onChange={onOrderDatehange}
                value={orderDate}
                locale="es/PY"
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm"
                closeOnSelect={true}
              ></Datetime>
              <FieldErrors
                errors={getFieldErrors("date", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Monto:</CLabel>
            </CCol>
            <CCol md={4}>
              <CurrencyField
                currency={PYG}
                disabled={false}
                value={
                  editingDebtLog.amount !== undefined
                    ? editingDebtLog.amount
                    : 0
                }
                onChange={onAmountChange}
              ></CurrencyField>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Observación:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingDebtLog.obs)}
                onChange={onObsChange}
                placeholder="Observación"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("obs", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            {editingDebtLog.id === undefined ? (
              <CButton
                type="button"
                color="info"
                onClick={onAutoFillClick}
                disabled={submitting}
              >
                Rellenar Fecha
              </CButton>
            ) : null}
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default StoreDebtLogForm;
