import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarMinimizer,
  //CSidebarNavDropdown,
  CSidebarNavItem,
  CSidebarNavDropdown,
} from "@coreui/react";

//import CIcon from '@coreui/icons-react';

import {
  minimizeDashboard,
  showDashboard,
} from "../../redux/actions/dashboard";
import { RootState } from "../../store";

// sidebar nav config
import { getRouteName, getRoutePath } from "../../routes";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import {
  BATCH_AMOUNTS_RETRIEVE,
  CLIENTS_RETRIEVE,
  CLOTHING_MODELS_RETRIEVE,
  COLORS_RETRIEVE,
  DELIVERY_ORDERS_RETRIEVE,
  DISCOUNTS_RETRIEVE,
  INPUT_MATERIALS_HISTORY_RETRIEVE,
  INPUT_MATERIALS_RETRIEVE,
  INVENTORY_INPUT_BATCHES_RETRIEVE,
  INVENTORY_PRODUCTS_RETRIEVE,
  INVENTORY_TRANSFERS_RETRIEVE,
  PAYMENTS_RETRIEVE,
  PRODUCTION_RETRIEVE,
  PRODUCT_HISTORY_RETRIEVE,
  SALE_ORDERS_RETRIEVE,
  SIZES_RETRIEVE,
  STORE_EXPENSES_RETRIEVE,
  STORE_SALES_RETRIEVE,
  SUPPLIERS_RETRIEVE,
} from "../../auth/permissions";
import HasAnyPermissionComponent from "../permissions/HasAnyPermissionComponent";

const Sidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector((state: RootState) => state.dashboard.sidebarShow);
  const minimize = useSelector(
    (state: RootState) => state.dashboard.sidebarMinimize
  );

  const isInventoryNavPage = () => {
    return (
      window.location.pathname === getRoutePath("inventoryProducts") ||
      window.location.pathname === getRoutePath("inventoryInputBatches") ||
      window.location.pathname === getRoutePath("inputMaterials") ||
      window.location.pathname === getRoutePath("inventoryProductLogs") ||
      window.location.pathname === getRoutePath("inputMaterialLogs")
    );
  };

  const isProductionNavPage = () => {
    return (
      window.location.pathname === getRoutePath("production") ||
      window.location.pathname === getRoutePath("products") ||
      window.location.pathname === getRoutePath("colors") ||
      window.location.pathname === getRoutePath("sizes")
    );
  };

  const isSalesNavPage = () => {
    return (
      window.location.pathname === getRoutePath("deliveryOrders") ||
      window.location.pathname === getRoutePath("payments") ||
      window.location.pathname === getRoutePath("saleOrders") ||
      window.location.pathname === getRoutePath("saleOrderSummary")
    );
  };

  const isStoresNavPage = () => {
    return (
      window.location.pathname === getRoutePath("inventoryTransfers") ||
      window.location.pathname === getRoutePath("stores") ||
      window.location.pathname === getRoutePath("discounts") ||
      window.location.pathname === getRoutePath("salesHistory") ||
      window.location.pathname === getRoutePath("storeRequestList") ||
      window.location.pathname === getRoutePath("inventoryCategorySummary") ||
      window.location.pathname === getRoutePath("cashRegisterHistory") ||
      window.location.pathname === getRoutePath("storeExpensesList")
    );
  };

  const [showInventoryNav, setShowInventoryNav] = useState(
    isInventoryNavPage()
  );

  const [showProductionNav, setShowProductionNav] = useState(
    isProductionNavPage()
  );

  const [showSalesNav, setShowSalesNav] = useState(isSalesNavPage());

  const [showStoresNav, setShowStoresNav] = useState(isStoresNavPage());

  const history = useHistory();

  useEffect(() => {
    return history.listen((_) => {
      setShowInventoryNav(isInventoryNavPage());
      setShowProductionNav(isProductionNavPage());
      setShowSalesNav(isSalesNavPage());
      setShowStoresNav(isStoresNavPage());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const pendingTransfersCount = useSelector(
    (state: RootState) => state.transfers.pendingTransfers
  );
  const pendingStoreRequestsCount = useSelector(
    (state: RootState) => state.transfers.pendingRequests
  );

  const badgeTrasfers: object | undefined = pendingTransfersCount
    ? { color: "danger", text: pendingTransfersCount }
    : undefined;

  const badgeStoreRequests: object | undefined = pendingStoreRequestsCount
    ? { color: "danger", text: pendingStoreRequestsCount }
    : undefined;

  return (
    <CSidebar
      show={show}
      onShowChange={(val: boolean) => {
        dispatch(showDashboard(val));
        window.localStorage.setItem("sidebarShow", val ? "true" : "false");
      }}
      minimize={minimize}
      onMinimizeChange={(val: boolean) => {
        dispatch(minimizeDashboard(!val));
        window.localStorage.setItem("sidebarMinimize", !val ? "true" : "false");
      }}
      dropdownMode={"noAction"}
    >
      <CSidebarBrand className="d-md-down-none" to="/"></CSidebarBrand>
      <CSidebarNav>
        <CSidebarNavItem
          name={getRouteName("dashboard")}
          to={getRoutePath("dashboard")}
          icon={"cil-home"}
        ></CSidebarNavItem>
        <PermissionRequiredComponent permissionName={SUPPLIERS_RETRIEVE}>
          <CSidebarNavItem
            name={getRouteName("suppliers")}
            to={getRoutePath("suppliers")}
            icon={<i className="c-sidebar-nav-icon fa fa-truck"></i>}
          ></CSidebarNavItem>
        </PermissionRequiredComponent>
        <PermissionRequiredComponent permissionName={CLIENTS_RETRIEVE}>
          <CSidebarNavItem
            name={getRouteName("clients")}
            to={getRoutePath("clients")}
            icon={<i className="c-sidebar-nav-icon fa fa-person"></i>}
          ></CSidebarNavItem>
        </PermissionRequiredComponent>

        <HasAnyPermissionComponent
          permissionNames={[
            PRODUCTION_RETRIEVE,
            CLOTHING_MODELS_RETRIEVE,
            COLORS_RETRIEVE,
            SIZES_RETRIEVE,
          ]}
        >
          <CSidebarNavDropdown
            name="Producción"
            icon={<i className="c-sidebar-nav-icon fa fa-industry"></i>}
            show={showProductionNav}
          >
            <PermissionRequiredComponent permissionName={PRODUCTION_RETRIEVE}>
              <CSidebarNavItem
                name={getRouteName("production")}
                to={getRoutePath("production")}
                icon={<i className="c-sidebar-nav-icon fa fa-scissors"></i>}
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent
              permissionName={CLOTHING_MODELS_RETRIEVE}
            >
              <CSidebarNavItem
                name={getRouteName("barcodePrint")}
                to={getRoutePath("barcodePrint")}
                icon={<i className="c-sidebar-nav-icon fa fa-barcode"></i>}
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent
              permissionName={CLOTHING_MODELS_RETRIEVE}
            >
              <CSidebarNavItem
                name={getRouteName("products")}
                to={getRoutePath("products")}
                icon={<i className="c-sidebar-nav-icon fa fa-shirt"></i>}
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent
              permissionName={CLOTHING_MODELS_RETRIEVE}
            >
              <CSidebarNavItem
                name={getRouteName("categories")}
                to={getRoutePath("categories")}
                icon={<i className="c-sidebar-nav-icon fa fa-layer-group"></i>}
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent permissionName={COLORS_RETRIEVE}>
              <CSidebarNavItem
                name={getRouteName("colors")}
                to={getRoutePath("colors")}
                icon={<i className="c-sidebar-nav-icon fa fa-droplet"></i>}
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent permissionName={SIZES_RETRIEVE}>
              <CSidebarNavItem
                name={getRouteName("sizes")}
                to={getRoutePath("sizes")}
                icon={
                  <i className="c-sidebar-nav-icon fa fa-up-right-and-down-left-from-center"></i>
                }
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
          </CSidebarNavDropdown>
        </HasAnyPermissionComponent>
        <HasAnyPermissionComponent
          permissionNames={[
            INVENTORY_PRODUCTS_RETRIEVE,
            INPUT_MATERIALS_RETRIEVE,
            INVENTORY_INPUT_BATCHES_RETRIEVE,
          ]}
        >
          <CSidebarNavDropdown
            name="Inventario"
            icon={<i className="c-sidebar-nav-icon fa fa-boxes-stacked"></i>}
            show={showInventoryNav}
          >
            <PermissionRequiredComponent
              permissionName={INVENTORY_PRODUCTS_RETRIEVE}
            >
              <CSidebarNavItem
                name={getRouteName("inventoryProducts")}
                to={getRoutePath("inventoryProducts")}
                icon={<i className="c-sidebar-nav-icon fa fa-shirt"></i>}
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent
              permissionName={INPUT_MATERIALS_RETRIEVE}
            >
              <CSidebarNavItem
                name={getRouteName("inputMaterials")}
                to={getRoutePath("inputMaterials")}
                icon={"cil-layers"}
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent
              permissionName={INVENTORY_INPUT_BATCHES_RETRIEVE}
            >
              <CSidebarNavItem
                name={getRouteName("inventoryInputBatches")}
                to={getRoutePath("inventoryInputBatches")}
                icon={<i className="c-sidebar-nav-icon fa fa-list"></i>}
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent
              permissionName={BATCH_AMOUNTS_RETRIEVE}
            >
              <CSidebarNavItem
                name={getRouteName("batchAmounts")}
                to={getRoutePath("batchAmounts")}
                icon={<i className="c-sidebar-nav-icon fa fa-chart-line"></i>}
              ></CSidebarNavItem>
            </PermissionRequiredComponent>

            <PermissionRequiredComponent
              permissionName={PRODUCT_HISTORY_RETRIEVE}
            >
              <CSidebarNavItem
                name={getRouteName("inventoryProductLogs")}
                to={getRoutePath("inventoryProductLogs")}
                icon={
                  <i className="c-sidebar-nav-icon fa fa-clock-rotate-left"></i>
                }
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent
              permissionName={INPUT_MATERIALS_HISTORY_RETRIEVE}
            >
              <CSidebarNavItem
                name={getRouteName("inputMaterialLogs")}
                to={getRoutePath("inputMaterialLogs")}
                icon={
                  <i className="c-sidebar-nav-icon fa fa-clock-rotate-left"></i>
                }
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
          </CSidebarNavDropdown>
        </HasAnyPermissionComponent>
        <HasAnyPermissionComponent
          permissionNames={[
            SALE_ORDERS_RETRIEVE,
            DELIVERY_ORDERS_RETRIEVE,
            PAYMENTS_RETRIEVE,
          ]}
        >
          <CSidebarNavDropdown
            name="Ventas"
            icon={<i className="c-sidebar-nav-icon fa fa-dollar"></i>}
            show={showSalesNav}
          >
            <PermissionRequiredComponent permissionName={SALE_ORDERS_RETRIEVE}>
              <CSidebarNavItem
                name={getRouteName("saleOrders")}
                to={getRoutePath("saleOrders")}
                icon={<i className="c-sidebar-nav-icon fa fa-coins"></i>}
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent
              permissionName={DELIVERY_ORDERS_RETRIEVE}
            >
              <CSidebarNavItem
                name={getRouteName("deliveryOrders")}
                to={getRoutePath("deliveryOrders")}
                icon={
                  <i className="c-sidebar-nav-icon fa fa-truck-ramp-box"></i>
                }
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent permissionName={PAYMENTS_RETRIEVE}>
              <CSidebarNavItem
                name={getRouteName("payments")}
                to={getRoutePath("payments")}
                icon={<i className="c-sidebar-nav-icon fa fa-money-bill"></i>}
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent permissionName={SALE_ORDERS_RETRIEVE}>
              <CSidebarNavItem
                name={getRouteName("saleOrderSummary")}
                to={getRoutePath("saleOrderSummary")}
                icon={<i className="c-sidebar-nav-icon fa fa-layer-group"></i>}
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
          </CSidebarNavDropdown>
        </HasAnyPermissionComponent>
        <HasAnyPermissionComponent
          permissionNames={[
            INVENTORY_TRANSFERS_RETRIEVE,
            STORE_SALES_RETRIEVE,
            DISCOUNTS_RETRIEVE,
            INVENTORY_PRODUCTS_RETRIEVE,
            STORE_EXPENSES_RETRIEVE,
          ]}
        >
          <CSidebarNavDropdown
            name="Gestion de Tiendas"
            icon={<i className="c-sidebar-nav-icon fa fa-cash-register"></i>}
            show={showStoresNav}
          >
            <PermissionRequiredComponent
              permissionName={INVENTORY_PRODUCTS_RETRIEVE}
            >
              <CSidebarNavItem
                name={getRouteName("stores")}
                to={getRoutePath("stores")}
                icon={<i className="c-sidebar-nav-icon fa fa-store"></i>}
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent
              permissionName={INVENTORY_TRANSFERS_RETRIEVE}
            >
              <CSidebarNavItem
                name={getRouteName("inventoryTransfers")}
                to={getRoutePath("inventoryTransfers")}
                icon={
                  <i className="c-sidebar-nav-icon fa fa-truck-ramp-box"></i>
                }
                badge={badgeTrasfers}
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent
              permissionName={INVENTORY_TRANSFERS_RETRIEVE}
            >
              <CSidebarNavItem
                name={getRouteName("storeRequestList")}
                to={getRoutePath("storeRequestList")}
                icon={
                  <i className="c-sidebar-nav-icon fa fa-tent-arrow-left-right"></i>
                }
                badge={badgeStoreRequests}
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent
              permissionName={STORE_EXPENSES_RETRIEVE}
            >
              <CSidebarNavItem
                name={getRouteName("storeExpensesList")}
                to={getRoutePath("storeExpensesList")}
                icon={<i className="c-sidebar-nav-icon fa fa-wallet"></i>}
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent permissionName={DISCOUNTS_RETRIEVE}>
              <CSidebarNavItem
                name={getRouteName("discounts")}
                to={getRoutePath("discounts")}
                icon={<i className="c-sidebar-nav-icon fa fa-tag"></i>}
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent permissionName={STORE_SALES_RETRIEVE}>
              <CSidebarNavItem
                name={getRouteName("salesHistory")}
                to={getRoutePath("salesHistory")}
                icon={<i className="c-sidebar-nav-icon fa fa-money-bill"></i>}
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent permissionName={STORE_SALES_RETRIEVE}>
              <CSidebarNavItem
                name={getRouteName("cashRegisterHistory")}
                to={getRoutePath("cashRegisterHistory")}
                icon={
                  <i className="c-sidebar-nav-icon fa fa-cash-register"></i>
                }
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent permissionName={STORE_SALES_RETRIEVE}>
              <CSidebarNavItem
                name={getRouteName("amountsSummary")}
                to={getRoutePath("amountsSummary")}
                icon={
                  <i className="c-sidebar-nav-icon fa fa-cash-register"></i>
                }
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
            <PermissionRequiredComponent permissionName={STORE_SALES_RETRIEVE}>
              <CSidebarNavItem
                name={getRouteName("inventoryCategorySummary")}
                to={getRoutePath("inventoryCategorySummary")}
                icon={<i className="c-sidebar-nav-icon fa fa-layer-group"></i>}
              ></CSidebarNavItem>
            </PermissionRequiredComponent>
          </CSidebarNavDropdown>
        </HasAnyPermissionComponent>
        <CSidebarNavItem
          name={getRouteName("users")}
          to={getRoutePath("users")}
          icon={"cil-people"}
        ></CSidebarNavItem>
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default Sidebar;
