import {
  CButton,
  CCol,
  CContainer,
  CInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getList, updateItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert, warningAlert } from "../utils/messages";
import ProductionOrder, {
  ProductionOrderSize,
} from "../../models/production-order";
import ProductionStage from "../../models/production-stage";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Errors, { getFieldErrors } from "../../models/errors";
import Size from "../../models/size";
import { emptyValueOnUndefined } from "../../utils/fields";

interface ProductionSizesModalProps {
  show: boolean;
  productionOrder?: ProductionOrder;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const ProductionSizesModal: React.FC<ProductionSizesModalProps> = ({
  show,
  productionOrder,
  onCancel,
  onSuccess,
}) => {
  const FIXED_COLUMNS = ["Talles disponibles"];
  const companyId = useSelector(
    (state: RootState) => state.company.data.company.id
  );
  const [submitting, setSubmitting] = useState(false);
  const [stage, setStage] = useState<ProductionStage | null>(null);
  const [cutStage, setCutStage] = useState<ProductionStage | null>(null);
  const [cutSizes, setCutSizes] = useState<ProductionOrderSize[]>([]);
  const [tableColumns, setTableColumns] = useState<string[]>(FIXED_COLUMNS);
  const [assignedAmount, setAssignedAmount] = useState<number>(0);

  const [errors, setErrors] = useState<Errors>({});

  const message = productionOrder
    ? `Formulario de corte de proceso de producción #${productionOrder.id}, ${productionOrder.productCodeIdentifier} - ${productionOrder.productName}`
    : "";

  const getConfectionStage = async () => {
    const limit = 100;
    const offset = 0;
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("company_id", companyId!.toString());
    const stagesStatus = await getList<ProductionStage>(
      `/production_stages/`,
      limit,
      offset,
      additionalParams
    );
    if (stagesStatus.status === SUCCESS) {
      if (stagesStatus.data !== undefined) {
        const newStage = stagesStatus.data.items.find(
          (item) => item.isConfection
        );
        setStage(newStage !== undefined ? newStage : null);
      }
    } else {
      setStage(null);
    }
  };

  const getCutStage = async () => {
    const limit = 100;
    const offset = 0;
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("company_id", companyId!.toString());
    const stagesStatus = await getList<ProductionStage>(
      `/production_stages/`,
      limit,
      offset,
      additionalParams
    );
    if (stagesStatus.status === SUCCESS) {
      if (stagesStatus.data !== undefined) {
        const newStage = stagesStatus.data.items.find((item) => item.isCut);
        setCutStage(newStage !== undefined ? newStage : null);
      }
    } else {
      setCutStage(null);
    }
  };

  const onAmountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, sizeId: number | undefined) => {
      const newAmountNumber = Number(e.target.value);

      if (sizeId !== undefined) {
        const newTableEntries = cutSizes.map((entry) => {
          if (entry.sizeId === sizeId) {
            return {
              ...entry,
              amount: newAmountNumber,
            };
          }
          return entry;
        });
        setCutSizes(newTableEntries);
        let totalAmount = 0;

        newTableEntries.forEach((entry) => {
          totalAmount += entry.amount || 0;
        });

        setAssignedAmount(totalAmount);
      }
    },
    [cutSizes]
  );

  const fetchAvailableSizes = async () => {
    if (productionOrder?.productId === undefined) {
      setCutSizes([]);
      return;
    }
    const productIdStr = productionOrder.productId.toString();
    const limit = 100;
    const offset = 0;
    const additional = new Map();
    additional.set("clothing_product_id", productIdStr);

    try {
      const sizesStatus = await getList<Size>(
        "/sizes/",
        limit,
        offset,
        additional
      );

      if (sizesStatus.status === SUCCESS && sizesStatus.data !== undefined) {
        const formattedSizes = sizesStatus.data.items.map((size) => {
          const newCutSize: ProductionOrderSize = {
            sizeId: size.id,
            orderId: productionOrder.id,
            sizeName: size.size,
            size: size,
            amount: 0,
          };
          return newCutSize;
        });

        setCutSizes(formattedSizes);
        generateTableHeaders(sizesStatus.data.items);
      } else {
        setCutSizes([]);
      }
    } catch (error) {
      console.error("Error fetching variants:", error);

      setCutSizes([]);
    }
  };

  const generateTableHeaders = (entries: Size[]) => {
    const uniqueSizeNames: string[] = [];

    entries.forEach((item) => {
      if (item.size && !uniqueSizeNames.includes(item.size)) {
        uniqueSizeNames.push(item.size);
      }
    });

    setTableColumns([...FIXED_COLUMNS, ...uniqueSizeNames]);
  };

  useEffect(() => {
    getCutStage();
    getConfectionStage();
    fetchAvailableSizes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionOrder]);

  const onSubmitClick = async () => {
    if (productionOrder !== undefined) {
      setSubmitting(true);

      let toSendSizes = cutSizes.filter(
        (size) => size.amount !== undefined && size.amount > 0
      );
      if (toSendSizes.length <= 0) {
        warningAlert(
          "Se debe seleccionar al menos una tamaño y una cantidad cortada"
        );
        setSubmitting(false);
        return;
      }

      toSendSizes = toSendSizes.map((item) => ({
        ...item,
        orderId: productionOrder.id,
        size: undefined,
      }));

      const totalAmount: number = toSendSizes.reduce(
        (sum, item) => sum + (item.amount || 0),
        0
      );

      let toSendOrder: ProductionOrder = {
        ...productionOrder,
        cutAmounts: toSendSizes,
        amountProduced: totalAmount,
        cutOrConfectionAmount: totalAmount,
        currentStageId: stage !== null ? stage.id : undefined,
        currentStage: stage !== null ? stage.name : undefined,
        currentStageAllowRelatedAmounts:
          stage !== null ? stage.allowRelatedAmounts : undefined,
        currentStageIsConfection: true,
        currentStageIsCut: false,
        stageAmount: totalAmount,
        originStageId: cutStage !== null ? cutStage.id : undefined,
        remainingAmountToDefine: totalAmount,
      };

      const orderStatus = await updateItem<ProductionOrder>(
        `/production/${productionOrder.id}/advance/`,
        toSendOrder
      );

      if (orderStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (orderStatus.errors !== undefined) {
          setErrors(orderStatus.errors);
        }
        if (orderStatus.detail !== undefined) {
          message = orderStatus.detail;
        }
        errorAlert(message);
      } else {
        setErrors({});
        onSuccess();
        setStage(null);
        setAssignedAmount(0);
      }

      setSubmitting(false);
    }
  };

  useEffect(() => {
    setStage(null);
    setAssignedAmount(0);
    setCutSizes([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionOrder]);

  const cutAmountsErrors = getFieldErrors("cutAmounts", errors) as Errors[];

  return (
    <CModal show={show} className="modal-primary" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Formulario de Corte</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h5>{message}</h5>
              <p>Indique las cantidades por tamaño que fueron cortadas</p>
            </CCol>
          </CRow>
          <CRow>
            <CCol md={12}>
              <div className="table-responsive">
                <table className="table table-striped table-bordered table-fixed">
                  <thead>
                    <tr>
                      {tableColumns.map((title, ix) => {
                        return (
                          <th
                            className="text-center"
                            key={ix}
                            style={{
                              verticalAlign: "middle",
                              overflow: "hidden",
                            }}
                          >
                            <div className="d-inline">{title}</div>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      style={{
                        verticalAlign: "middle",
                        overflow: "hidden",
                      }}
                    >
                      <td style={{ padding: 0, width: "300px" }}>
                        {productionOrder?.productName}
                      </td>
                      {cutSizes.map((tableEntry, ix) => {
                        return (
                          <td key={ix} style={{ padding: 0 }}>
                            <CInput
                              type="number"
                              value={emptyValueOnUndefined(tableEntry.amount)}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => onAmountChange(e, tableEntry.sizeId)}
                            ></CInput>
                          </td>
                        );
                      })}
                    </tr>
                    {cutSizes.length === 0 ? (
                      <tr>
                        <td colSpan={tableColumns.length}>
                          No hay tamaños disponibles para esta prenda.
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
                <br />
                <br />
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <span> CANTIDAD TOTAL ASIGNADA: {assignedAmount}</span>
        <CButton disabled={submitting} onClick={onSubmitClick} color="primary">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Guardando..." : "Guardar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ProductionSizesModal;
