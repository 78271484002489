import ClientCrudPage from "./dashboard-pages/ClientCrudPage";
import DashboardPage from "./dashboard-pages/DashboardPage";
import InputMaterialCrudPage from "./dashboard-pages/InputMaterialCrudPage";
import ProductCrudPage from "./dashboard-pages/ProductCrudPage";
import SupplierCrudPage from "./dashboard-pages/SupplierCrudPage";
import StoreCrudPage from "./dashboard-pages/StoreCrudPage";
import UserAuditPage from "./dashboard-pages/UserAuditPage";
import UserCrudPage from "./dashboard-pages/UserCrudPage";
import InventoryInputBatchCrudPage from "./dashboard-pages/InventoryInputBatchCrudPage";
import ColorCrudPage from "./dashboard-pages/ColorCrudPage";
import SizeCrudPage from "./dashboard-pages/SizeCrudPage";
import ProductionCrudPage from "./dashboard-pages/ProductionCrudPage";
import InventoryProductListPage from "./dashboard-pages/InventoryProductListPage";
import SaleOrderCrudPage from "./dashboard-pages/SaleOrderCrudPage";
import SaleOrderFormPage from "./dashboard-pages/SaleOrderFormPage";
import DeliveryOrderCrudPage from "./dashboard-pages/DeliveryOrderCrudPage";
import DeliveryOrderFormPage from "./dashboard-pages/DeliveryOrderFormPage";
import ClientPaymentListPage from "./dashboard-pages/ClientPaymentListPage";
import PaymentCrudPage from "./dashboard-pages/PaymentCrudPage";
import {
  BATCH_AMOUNTS_RETRIEVE,
  CLIENTS_RETRIEVE,
  CLOTHING_MODELS_RETRIEVE,
  COLORS_RETRIEVE,
  INPUT_MATERIALS_HISTORY_RETRIEVE,
  PRODUCTION_RETRIEVE,
  PRODUCT_HISTORY_RETRIEVE,
  SIZES_RETRIEVE,
  STORE_EXPENSES_RETRIEVE,
  SUPPLIERS_RETRIEVE,
  USERS_RETRIEVE,
} from "./auth/permissions";
import InventoryTransferCrudPage from "./dashboard-pages/InventoryTransferCrudPage";
import InventoryTransferFormPage from "./dashboard-pages/InventoryTransferFormPage";
import ProductHistoryPage from "./dashboard-pages/ProductHistoryPage";
import InputMaterialHistoryPage from "./dashboard-pages/InputMaterialHistoryPage";
import StoreInventoryProductListPage from "./dashboard-pages/StoreInventoryProductListPage";
import BarcodeFormPage from "./dashboard-pages/BarcodeFormPage";
import CategoryCrudPage from "./dashboard-pages/CategoryPage";
import DiscountCrudPage from "./dashboard-pages/DiscountCrudPage";
import SalesHistoryPage from "./dashboard-pages/SalesHistoryPage";
import CashRegisterHistoryPage from "./dashboard-pages/CashRegisterHistoryPage";
import AmountsSummaryPage from "./dashboard-pages/AmountsSummaryPage";
import StoreDebtListPage from "./dashboard-pages/StoreDebtListPage";
import BatchAmountListPage from "./dashboard-pages/BatchAmountsPage";
import BatchAmountLogsPage from "./dashboard-pages/BatchAmountLogsPage";
import StoreRequestListPage from "./dashboard-pages/StoreRequestListPage";
import InventoryCategorySummaryPage from "./dashboard-pages/InventoryCategorySummaryPage";
import SaleOrderSummaryPage from "./dashboard-pages/SaleOrderSummaryPage";
import StoreExpenseListPage from "./dashboard-pages/StoreExpenseListPage";

interface Route {
  path: string;
  exact?: boolean | undefined;
  name: string;
  component?: any;
  permissionName?: string;
}

interface RouteMap {
  [index: string]: Route;
}

const routes: RouteMap = {
  dashboard: {
    path: "/dashboard/",
    name: "Inicio",
    exact: true,
    component: DashboardPage,
  },
  users: {
    path: "/users/",
    name: "Usuarios",
    exact: true,
    component: UserCrudPage,
    permissionName: USERS_RETRIEVE,
  },
  suppliers: {
    path: "/suppliers/",
    name: "Proveedores",
    exact: true,
    component: SupplierCrudPage,
    permissionName: SUPPLIERS_RETRIEVE,
  },
  inputMaterials: {
    path: "/input_materials/",
    name: "Materias Primas",
    exact: true,
    component: InputMaterialCrudPage,
  },
  inventoryInputBatches: {
    path: "/inventory_locations/input_batches/",
    name: "Tandas de Materia prima",
    exact: true,
    component: InventoryInputBatchCrudPage,
  },
  products: {
    path: "/products/",
    name: "Modelos de Prendas",
    exact: true,
    component: ProductCrudPage,
    permissionName: CLOTHING_MODELS_RETRIEVE,
  },
  clients: {
    path: "/clients/",
    name: "Clientes",
    exact: true,
    component: ClientCrudPage,
    permissionName: CLIENTS_RETRIEVE,
  },
  userAudit: {
    path: "/user_audit/",
    name: "Auditoría de usuarios",
    exact: true,
    component: UserAuditPage,
  },
  stores: {
    path: "/stores/",
    name: "Tiendas",
    exact: true,
    component: StoreCrudPage,
  },
  colors: {
    path: "/colors/",
    name: "Colores",
    exact: true,
    component: ColorCrudPage,
    permissionName: COLORS_RETRIEVE,
  },
  sizes: {
    path: "/sizes/",
    name: "Talles",
    exact: true,
    component: SizeCrudPage,
    permissionName: SIZES_RETRIEVE,
  },
  production: {
    path: "/production/",
    name: "Producir",
    exact: true,
    component: ProductionCrudPage,
    permissionName: PRODUCTION_RETRIEVE,
  },
  inventoryProducts: {
    path: "/inventory_products/",
    name: "Productos",
    exact: true,
    component: InventoryProductListPage,
  },
  saleOrders: {
    path: "/sale_orders/",
    name: "Ordenes de venta",
    exact: true,
    component: SaleOrderCrudPage,
  },
  saleOrderForm: {
    path: "/sale_orders/:id",
    name: "Formulario orden de venta",
    exact: true,
    component: SaleOrderFormPage,
  },
  saleOrderFormCreate: {
    path: "/sale_orders/create",
    name: "Formulario orden de venta",
    exact: true,
    component: SaleOrderFormPage,
  },
  deliveryOrders: {
    path: "/delivery_orders/",
    name: "Ordenes de envío",
    exact: true,
    component: DeliveryOrderCrudPage,
  },
  deliveryOrderDetail: {
    path: "/delivery_orders/:id",
    name: "Detalle orden de envío",
    exact: true,
    component: DeliveryOrderFormPage,
  },
  deliveryOrderFormCreate: {
    path: "/delivery_orders/create",
    name: "Formulario orden de envío",
    exact: true,
    component: DeliveryOrderFormPage,
  },
  payments: {
    path: "/payments/",
    name: "Pagos",
    exact: true,
    component: PaymentCrudPage,
  },
  clientPayments: {
    path: "/client/:id/payments/",
    name: "Pagos de cliente",
    exact: true,
    component: ClientPaymentListPage,
  },
  inventoryTransfers: {
    path: "/inventory_transfers/",
    name: "Envío a tiendas",
    exact: true,
    component: InventoryTransferCrudPage,
  },
  inventoryTransferForm: {
    path: "/inventory_transfers/create",
    name: "Formulario de envío a tiendas",
    exact: true,
    component: InventoryTransferFormPage,
  },
  inventoryTransferFormUpdate: {
    path: "/inventory_transfers/:id",
    name: "Formulario de envío a tiendas",
    exact: true,
    component: InventoryTransferFormPage,
  },
  inventoryProductLogs: {
    path: "/product_history_logs/",
    name: "Historial Productos",
    exact: true,
    component: ProductHistoryPage,
    permissionName: PRODUCT_HISTORY_RETRIEVE,
  },
  inputMaterialLogs: {
    path: "/input_material_history_logs/",
    name: "Historial Mat. Primas",
    exact: true,
    component: InputMaterialHistoryPage,
    permissionName: INPUT_MATERIALS_HISTORY_RETRIEVE,
  },
  storeInventory: {
    path: "/stores/:id/inventory/",
    name: "Inventario de Sucursal",
    exact: true,
    component: StoreInventoryProductListPage,
  },
  barcodePrint: {
    path: "/barcode_print/new",
    name: "Imprimir Código de barras",
    exact: true,
    component: BarcodeFormPage,
  },
  categories: {
    path: "/categories/",
    name: "Categorías",
    exact: true,
    component: CategoryCrudPage,
  },
  discounts: {
    path: "/discounts/",
    name: "Descuentos/promociones",
    exact: true,
    component: DiscountCrudPage,
  },
  salesHistory: {
    path: "/sales_history/",
    name: "Registro de ventas en sucursal",
    exact: true,
    component: SalesHistoryPage,
  },
  cashRegisterHistory: {
    path: "/cash_register_history/",
    name: "Registro de Caja",
    exact: true,
    component: CashRegisterHistoryPage,
  },
  amountsSummary: {
    path: "/amounts_summary/",
    name: "Resumen Categorías",
    exact: true,
    component: AmountsSummaryPage,
  },
  inventoryCategorySummary: {
    path: "/inventory_summary/",
    name: "Inventario por Categorías",
    exact: true,
    component: InventoryCategorySummaryPage,
  },
  saleOrderSummary: {
    path: "/sale_orders_summary/",
    name: "Resumen O. de Venta",
    exact: true,
    component: SaleOrderSummaryPage,
  },
  storeDebtList: {
    path: "/stores/:id/debt_log/",
    name: "Deuda de Sucursal",
    exact: true,
    component: StoreDebtListPage,
  },
  batchAmounts: {
    path: "/batch_amounts/",
    name: "Rendimiento Materia Prima",
    exact: true,
    component: BatchAmountListPage,
    permissionName: BATCH_AMOUNTS_RETRIEVE,
  },
  batchAmountsLogs: {
    path: "/batch_amounts/:id/logs/",
    name: "Movimientos de lote de Tela",
    exact: true,
    component: BatchAmountLogsPage,
    permissionName: BATCH_AMOUNTS_RETRIEVE,
  },
  storeRequestList: {
    path: "/store_requests/",
    name: "Pedidos de Sucursal",
    exact: true,
    component: StoreRequestListPage,
  },
  storeExpensesList: {
    path: "/store_expenses/",
    name: "Gastos de Sucursal",
    exact: true,
    component: StoreExpenseListPage,
    permissionName: STORE_EXPENSES_RETRIEVE,
  },
};

export const getRoutePath = (key: string) => {
  return routes[key].path;
};
export const getRouteName = (key: string) => {
  return routes[key].name;
};

export default routes;
