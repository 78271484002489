import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import SoldSumaryEntry from "../models/sold-summary";

const lungorLogo = require("../assets/img/lungorlogo.jpg");

interface InventorySummaryPDFEntry {
  name: string;
  amount: string;
  saleValue: string;
}

const convertItemsToPDFEntries = (
  soldSummaryentries: SoldSumaryEntry[]
): InventorySummaryPDFEntry[] => {
  return soldSummaryentries.map((entry) => {
    const newValue: InventorySummaryPDFEntry = {
      name: entry.name || "-",
      amount: entry.amount.toString() || "0",
      saleValue: entry.soldPrice.toLocaleString() || "-",
    };
    return newValue;
  });
};

type FontStyle = "normal" | "bold" | "italic";
const generateInventorySummaryPdf = async (
  receivedEntries: SoldSumaryEntry[],
  titlesMiddle: string[],
  filters: string[]
) => {
  const doc = new jsPDF({
    orientation: "portrait",
    format: "a4",
  });
  const entries = convertItemsToPDFEntries(receivedEntries);

  const totals = calculateTotals(receivedEntries);

  const entriesPerPage = 30;
  const totalPages = Math.ceil(entries.length / entriesPerPage);

  const headerStyles = {
    fontSize: 5,
    font: "helvetica",
    fontStyle: "bold" as FontStyle,
    fillColor: "#ffffff",
    textColor: "#000000",
    lineWidth: 0.1,
  };

  const bodyStyles = {
    fontSize: 5,
    font: "helvetica",
  };

  for (let currentPage = 1; currentPage <= totalPages; currentPage++) {
    if (currentPage > 1) {
      doc.addPage();
    }

    doc.setFontSize(10);
    generateHeader(doc, titlesMiddle, filters);

    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = Math.min(
      startIndex + entriesPerPage,
      receivedEntries.length
    );
    const pageEntries = entries.slice(startIndex, endIndex);

    if (currentPage === totalPages) {
      pageEntries.push(totals);
    }

    autoTable(doc, {
      theme: "grid",
      startY: 45,
      head: [["Nombre", "Cantidad", "Valor de Venta"]],
      body: pageEntries.map((entry) => Object.values(entry)),
      headStyles: headerStyles,
      bodyStyles: bodyStyles,
    });

    doc.setFontSize(5);
    doc.text(
      `Página ${currentPage} de ${totalPages}`,
      14,
      doc.internal.pageSize.height - 10
    );
  }

  const blob = doc.output("blob");
  const url = URL.createObjectURL(blob);
  window.open(url, "_blank");
};

const calculateTotals = (
  entries: SoldSumaryEntry[]
): InventorySummaryPDFEntry => {
  const soldPriceTotal: number = entries.reduce(
    (sum, item) => sum + (item.soldPrice || 0),
    0
  );

  const amountTotal: number = entries.reduce(
    (sum, item) => sum + (item.amount || 0),
    0
  );

  return {
    name: "",
    amount: amountTotal.toLocaleString(),
    saleValue: soldPriceTotal.toLocaleString(),
  };
};

const generateHeader = (
  doc: jsPDF,
  titlesMiddle: string[],
  titlesRight: string[]
) => {
  const x = 14;
  const y = 10;
  const width = doc.internal.pageSize.width - 28;
  const height = 30;
  const middleX = x + width / 2;

  doc.rect(x, y, width, height);

  const img = new Image();
  img.src = lungorLogo;
  doc.addImage(img, "JPEG", x + 5, y + 5, 35, 20);

  const totalTitlesMiddle = titlesMiddle.length;
  const totalTitlesRight = titlesRight.length;
  const totalTitles = Math.max(totalTitlesMiddle, totalTitlesRight);

  const verticalSpace = height / (totalTitles + 1);

  let middleTitleY = y + verticalSpace;

  titlesMiddle.forEach((title) => {
    const middleTitleWidth = doc.getTextWidth(title);
    const middleTitleX = middleX - middleTitleWidth / 2;
    doc.text(title, middleTitleX, middleTitleY);
    middleTitleY += verticalSpace;
  });

  doc.setFontSize(6);
  let rightTitleY = y + verticalSpace;

  const rightX = x + width;
  titlesRight.forEach((title) => {
    const rightTitleWidth = doc.getTextWidth(title);
    const rightTitleX = rightX - rightTitleWidth - 5;
    doc.text(title, rightTitleX, rightTitleY);
    rightTitleY += verticalSpace;
  });
};
export default generateInventorySummaryPdf;
