import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { createItem, updateItem, ItemRequestStatus } from "../../api/generics";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import moment from "moment";
import StoreExpense, { newStoreExpense } from "../../models/store-expense";
import CurrencyField from "../currencies/CurrencyField";
import { PYG } from "../../currency/available-currencies";
import SingleStoreSelect from "../store/SingleStoreSelect";
import UserSelect from "../user/UserSelect";
import Store from "../../models/store";
import User from "../../models/user";
import { formatToCurrency } from "../../currency/format";

interface StoreExpenseFormProps {
  initialStoreExpense?: StoreExpense;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const SupplierForm: React.FC<StoreExpenseFormProps> = ({
  initialStoreExpense,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);
  const [editingItem, setEditingItem] = useState<StoreExpense>(
    initialStoreExpense ? initialStoreExpense : newStoreExpense()
  );
  const [selectedStore, setSelectedStore] = useState<Store | undefined>(
    undefined
  );
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [selectedRevertUser, setSelectedRevertUser] = useState<
    User | undefined
  >(undefined);

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingItem({
      ...editingItem,
      description: e.target.value,
    });
  };

  const onUserChange = (newUser: User | null) => {
    setSelectedUser(newUser !== null ? newUser : undefined);
    setEditingItem({
      ...editingItem,
      userId: newUser !== null ? newUser.id : undefined,
      userName: newUser !== null ? newUser.username : undefined,
    });
  };

  const onRevertUserChange = (newUser: User | null) => {
    setSelectedRevertUser(newUser !== null ? newUser : undefined);
    setEditingItem({
      ...editingItem,
      revertUserId: newUser !== null ? newUser.id : undefined,
      revertUserName: newUser !== null ? newUser.username : undefined,
    });
  };

  const onStoreChange = (newStore: Store | null) => {
    setSelectedStore(newStore !== null ? newStore : undefined);
    setEditingItem({
      ...editingItem,
      storeId: newStore !== null ? newStore.id : undefined,
      storeName: newStore !== null ? newStore.name : undefined,
    });
  };

  const onRevertDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditingItem({
      ...editingItem,
      revertDescription: e.target.value,
    });
  };

  const onPriceChange = (newAmount: number | undefined) => {
    setEditingItem({
      ...editingItem,
      price: newAmount,
    });
  };

  const onSave = async () => {
    setSubmitting(true);
    let toSendExpense: StoreExpense = {
      ...editingItem,
      companyId: company.id,
    };

    const operationDate = moment().toISOString();

    if (toSendExpense.expenseType === undefined) {
      toSendExpense.expenseType = "in_factory";
    }

    if (toSendExpense.description === "") {
      toSendExpense.description = undefined;
    }
    if (toSendExpense.revertDescription === "") {
      toSendExpense.revertDescription = undefined;
    }

    let requestPromise: Promise<ItemRequestStatus<StoreExpense>>;
    if (editingItem.id === undefined) {
      toSendExpense.status = "registered";
      toSendExpense.expenseDate = operationDate;
      requestPromise = createItem<StoreExpense>(
        "/store_expenses/create/",
        toSendExpense
      );
    } else {
      if (selectedRevertUser === undefined) {
        setErrors({ revertUserName: ["Seleccione un usuario"] });
        setSubmitting(false);
        return;
      }
      if (
        editingItem.revertDescription === undefined ||
        editingItem.revertDescription === ""
      ) {
        setErrors({ revertDescription: ["Indique un motivo de reversión"] });
        setSubmitting(false);
        return;
      }
      toSendExpense.status = "reverted";
      toSendExpense.revertDate = operationDate;
      toSendExpense.reverted = true;
      requestPromise = updateItem<StoreExpense>(
        `/store_expenses/${toSendExpense.id}/`,
        toSendExpense
      );
    }

    const requestStatus = await requestPromise;

    if (requestStatus.status !== SUCCESS) {
      if (requestStatus.errors !== undefined) {
        setErrors(requestStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (requestStatus.detail !== undefined) {
        message = requestStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingItem(newStoreExpense());
    setSelectedRevertUser(undefined);
    setSelectedStore(undefined);
    setSelectedUser(undefined);
  };

  useEffect(() => {
    setEditingItem(
      initialStoreExpense ? initialStoreExpense : newStoreExpense()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStoreExpense]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        {editingItem.id === undefined ? (
          <>
            <CFormGroup>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Sucursal:</CLabel>
                </CCol>
                <CCol>
                  <SingleStoreSelect
                    value={selectedStore ? selectedStore : null}
                    onChange={onStoreChange}
                  ></SingleStoreSelect>
                  <FieldErrors
                    errors={getFieldErrors("storeName", errors) as string[]}
                  ></FieldErrors>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Usuario:</CLabel>
                </CCol>
                <CCol>
                  <UserSelect
                    value={selectedUser ? selectedUser : null}
                    onChange={onUserChange}
                  ></UserSelect>
                  <FieldErrors
                    errors={getFieldErrors("userName", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>

            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>Concepto:</CLabel>
                </CCol>
                <CCol>
                  <CInput
                    type="text"
                    value={emptyValueOnUndefined(editingItem.description)}
                    onChange={onDescriptionChange}
                    placeholder="Motivo del gasto"
                  ></CInput>
                  <FieldErrors
                    errors={getFieldErrors("description", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>Este pago:</CLabel>
                </CCol>
                <CCol>
                  <CurrencyField
                    currency={PYG}
                    value={editingItem.price}
                    onChange={onPriceChange}
                  ></CurrencyField>
                  <FieldErrors
                    errors={getFieldErrors("price", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
          </>
        ) : (
          <>
            <CFormGroup>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Sucursal:</CLabel>
                </CCol>
                <CCol>
                  <p className="mt-2">{editingItem.storeName}</p>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Usuario:</CLabel>
                </CCol>
                <CCol>
                  <p className="mt-2">{editingItem.userName}</p>
                </CCol>
              </CRow>
            </CFormGroup>

            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>Concepto:</CLabel>
                </CCol>
                <CCol>
                  <p className="mt-2">{editingItem.description}</p>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>Este pago:</CLabel>
                </CCol>
                <CCol>
                  <p className="mt-2">
                    {formatToCurrency(editingItem.price || 0, PYG)}
                  </p>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Usuario reversión:</CLabel>
                </CCol>
                <CCol>
                  <UserSelect
                    value={selectedRevertUser ? selectedRevertUser : null}
                    onChange={onRevertUserChange}
                  ></UserSelect>
                  <FieldErrors
                    errors={
                      getFieldErrors("revertUserName", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>

            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>Motivo reversión:</CLabel>
                </CCol>
                <CCol>
                  <CInput
                    type="text"
                    value={emptyValueOnUndefined(editingItem.revertDescription)}
                    onChange={onRevertDescriptionChange}
                    placeholder="Motivo de reversión"
                  ></CInput>
                  <FieldErrors
                    errors={
                      getFieldErrors("revertDescription", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
          </>
        )}
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default SupplierForm;
