import {
  CButton,
  CButtonGroup,
  CCol,
  CDataTable,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import { useEffect, useState, useCallback } from "react";
import { Spinner } from "react-bootstrap";
import { createItem, ItemRequestStatus, getList } from "../../api/generics";
import Product, { newProduct } from "../../models/product";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert, warningAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import Variant, {
  VariantAmountObject,
  VariantTableEntry,
  variantAPIToTable,
  variantTableFormatToAPIReceived,
} from "../../models/variant";
import InventoryLocation, {
  INVENTORY_LOCATION_TYPE_PRODUCTS,
} from "../../models/inventory-location";
import InventoryLocationSelect from "../inventory-location/InventoryLocationSelect";
import InventoryProduct, {
  MINIMUNAMOUNTPERSIZE,
} from "../../models/inventory-product";
import InventoryAsyncTooltip from "../inventory-products/InventoryAsyncTooltip";

interface ProductAmountFormProps {
  initialProduct?: Product;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}
const FIXED_COLUMNS = ["Color"];

interface FieldEntry {
  key: string;
  _classes: string[];
  label: string;
  filter?: boolean;
}

const ProductAmountForm: React.FC<ProductAmountFormProps> = ({
  initialProduct,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const [fields, setFields] = useState<FieldEntry[]>([
    {
      key: "codeIdentifier",
      _classes: ["text-center"],
      label: "Código",
    },
    {
      key: "name",
      _classes: ["text-center"],
      label: "Nombre",
    },
    {
      key: "color",
      _classes: ["text-center"],
      label: "Color",
    },
    {
      key: "totalAmount",
      _classes: ["text-center"],
      label: "Cantidad total",
    },
  ]);

  const company = useSelector((state: RootState) => state.company.data.company);

  const [editingProduct, setEditingProduct] = useState<Product>(
    initialProduct ? initialProduct : newProduct()
  );

  const [obs, setObs] = useState<string>("");

  const [tableEntries, setTableEntries] = useState<VariantTableEntry[]>([]);

  const [loading, setLoading] = useState(true);
  const [tableColumns, setTableColumns] = useState<string[]>(FIXED_COLUMNS);

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);
  const [inventoryLocation, setInventoryLocation] =
    useState<InventoryLocation | null>(null);

  const [inventoryProducts, setInventoryProducts] = useState<
    InventoryProduct[]
  >([]);

  const onLocationChange = (newLocation: InventoryLocation | null) => {
    setInventoryLocation(newLocation);
  };

  const onObsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setObs(e.target.value);
  };

  const fetchVariants = async (product: Product) => {
    if (product.id === undefined) {
      setTableEntries([]);
      return;
    }
    const productIdStr = product.id.toString();
    const limit = 100;
    const offset = 0;
    const additional = new Map();
    additional.set("clothing_product_id", productIdStr);

    try {
      const sizesStatus = await getList<Variant>(
        "/product_variants/with_related/",
        limit,
        offset,
        additional
      );

      if (sizesStatus.status === SUCCESS && sizesStatus.data !== undefined) {
        const formattedTableEntries = variantAPIToTable(sizesStatus.data.items);

        setTableEntries(formattedTableEntries);
        generateTableHeaders(formattedTableEntries);
      } else {
        setTableEntries([]);
      }
    } catch (error) {
      console.error("Error fetching variants:", error);

      setTableEntries([]);
    }
  };

  const fetchInventoryProducts = async () => {
    const additional = new Map();
    if (editingProduct.id) {
      additional.set("product_id", editingProduct.id?.toString());
    } else {
      setInventoryProducts([]);
      return;
    }

    if (inventoryLocation !== null && inventoryLocation.storeId) {
      additional.set("store_id", inventoryLocation.storeId?.toString());
    } else {
      setInventoryProducts([]);
      return;
    }

    const limit = 100;
    const offset = 0;
    const inventoryLocationsStatus = await getList<InventoryProduct>(
      "/inventory_products/",
      limit,
      offset,
      additional
    );
    if (inventoryLocationsStatus.status === SUCCESS) {
      if (inventoryLocationsStatus.data !== undefined) {
        if (
          inventoryLocationsStatus.data.count === 0 &&
          inventoryLocationsStatus.data.items.length === 0
        ) {
          setInventoryProducts([]);
          setLoading(false);
          return;
        }

        const newSizesFields: FieldEntry[] = [];
        const encounteredSizes = new Set();

        inventoryLocationsStatus.data.items.forEach((entry) => {
          entry.sizes?.forEach((size) => {
            if (
              typeof size.size === "string" &&
              !encounteredSizes.has(size.size)
            ) {
              const newFieldEntry = {
                key: size.size,
                _classes: ["text-center"],
                label: size.size,
                filter: false,
              };

              newSizesFields.push(newFieldEntry);
              encounteredSizes.add(size.size);
            }
          });
        });
        newSizesFields.sort((a, b) => {
          const keyLengthDiff = a.key.length - b.key.length;
          if (keyLengthDiff !== 0) {
            return keyLengthDiff;
          }
          return parseInt(a.key) - parseInt(b.key);
        });

        setFields((prevFields) => [
          ...prevFields.slice(0, 4),
          ...newSizesFields,
        ]);
        const flattenedInventoryProducts =
          inventoryLocationsStatus.data.items.map((entry) => {
            const flattenedProduct: { [key: string]: any } = { ...entry };
            newSizesFields?.forEach((size) => {
              if (typeof size.key === "string") {
                const sizeInfo = entry.sizes?.find(
                  (sizeinfo) => sizeinfo.size === size.key
                );
                if (sizeInfo) {
                  const difference =
                    sizeInfo.allocatedAmount !== undefined
                      ? sizeInfo.amount - sizeInfo.allocatedAmount
                      : sizeInfo.amount;
                  if (
                    sizeInfo.allocatedAmount &&
                    sizeInfo.allocatedAmount !== 0
                  ) {
                    flattenedProduct[
                      size.key
                    ] = `${sizeInfo.amount}(${sizeInfo.allocatedAmount})`;
                  } else {
                    flattenedProduct[size.key] = `${sizeInfo.amount}` || "0";
                  }
                  if (difference < MINIMUNAMOUNTPERSIZE) {
                    flattenedProduct[size.key] =
                      flattenedProduct[size.key] + "*";
                    flattenedProduct["hasMissingProducts"] = true;
                  }
                } else {
                  flattenedProduct[size.key] = "-";
                }
              }
            });
            return flattenedProduct;
          });
        setInventoryProducts(flattenedInventoryProducts);
      }
    } else {
      const message = inventoryLocationsStatus.detail
        ? inventoryLocationsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const generateTableHeaders = (entries: VariantTableEntry[]) => {
    const uniqueSizeNames: string[] = [];

    entries.forEach((item) => {
      if (item.sizes) {
        item.sizes
          .map((size) => size?.sizeName)
          .filter(
            (sizeName) =>
              sizeName !== undefined && !uniqueSizeNames.includes(sizeName)
          )
          .forEach((sizeName) => {
            if (sizeName) {
              uniqueSizeNames.push(sizeName);
            }
          });
      }
    });

    uniqueSizeNames.sort((a, b) => {
      const aFloat = parseFloat(a);
      const bFloat = parseFloat(b);
      return aFloat - bFloat;
    });

    setTableColumns([...FIXED_COLUMNS, ...uniqueSizeNames]);
  };

  const onAmountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, variantId: number | undefined) => {
      const newAmountNumber = Number(e.target.value);

      if (variantId !== undefined) {
        setTableEntries((prevTableEntries) =>
          prevTableEntries.map((entry) => {
            const updatedSizes = entry.sizes?.map((size) => {
              if (size.variantId === variantId) {
                return {
                  ...size,
                  amount: newAmountNumber,
                };
              }
              return size;
            });

            return {
              ...entry,
              sizes: updatedSizes,
            };
          })
        );
      }
    },
    []
  );

  const onSave = async () => {
    setSubmitting(true);

    const totalQuantity = tableEntries.reduce(
      (total, entry) =>
        total +
        (entry.productionCost || 0) *
          (entry.sizes?.reduce(
            (sizeTotal, size) => sizeTotal + (size.amount || 0),
            0
          ) || 0),
      0
    );

    const toSendAmounts = variantTableFormatToAPIReceived(tableEntries);

    if (toSendAmounts.length === 0) {
      warningAlert("Debes introducir al menos algun valor");
      setSubmitting(false);
      return;
    }

    let toSendData: VariantAmountObject = {
      companyId: company.id,
      inventoryLocationId:
        inventoryLocation !== null ? inventoryLocation.id : undefined,
      clothingProductId: editingProduct ? editingProduct.id : undefined,
      amounts: toSendAmounts,
      totalValue: totalQuantity,
    };

    if (obs !== "") toSendData.obs = obs;

    let requestPromise: Promise<ItemRequestStatus<VariantAmountObject>>;

    requestPromise = createItem<VariantAmountObject>(
      "/clothing_products/add_inventory_to_variants/",
      toSendData
    );

    const amountsStatus = await requestPromise;

    if (amountsStatus.status !== SUCCESS) {
      if (amountsStatus.errors !== undefined) {
        setErrors(amountsStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (amountsStatus.detail !== undefined) {
        message = amountsStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      setLoading(true);
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingProduct(newProduct());
    setObs("");
    setTableEntries([]);
    setInventoryLocation(null);
  };

  useEffect(() => {
    setLoading(true);
    setInventoryLocation(null);
    setEditingProduct(initialProduct ? initialProduct : newProduct());
    fetchVariants(initialProduct ? initialProduct : newProduct());
    setObs("");
    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialProduct]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  useEffect(() => {
    fetchInventoryProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryLocation, initialProduct]);

  const buttonText1 = loading ? "Cargando..." : "Guardar";
  const buttonText = submitting ? "Guardando..." : buttonText1;

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Inventario:</CLabel>
            </CCol>
            <CCol>
              <InventoryLocationSelect
                value={inventoryLocation}
                onChange={onLocationChange}
                inventoryType={INVENTORY_LOCATION_TYPE_PRODUCTS}
              ></InventoryLocationSelect>
              <FieldErrors
                errors={
                  getFieldErrors("inventoryLocationId", errors) as string[]
                }
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Observación:</CLabel>
            </CCol>
            <CCol md={10}>
              <CInput
                type="text"
                value={obs}
                onChange={onObsChange}
                placeholder="Complete aquí el motivo por el que se hace este ajuste de inventario"
              ></CInput>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <h5>Cantidades</h5>
            </CCol>
          </CRow>
          <CRow>
            <CCol md={12}>
              <div className="table-responsive">
                <table className="table table-striped table-bordered table-fixed">
                  <thead>
                    <tr>
                      {tableColumns.map((title, ix) => {
                        return (
                          <th
                            className="text-center"
                            key={ix}
                            style={{
                              verticalAlign: "middle",
                              overflow: "hidden",
                            }}
                          >
                            <div className="d-inline">{title}</div>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {tableEntries.map((tableEntry, ix) => {
                      return (
                        <tr
                          style={{
                            verticalAlign: "middle",
                            overflow: "hidden",
                          }}
                          key={ix}
                        >
                          <td
                            className={"text-center"}
                            style={{ padding: 0, width: "120px" }}
                          >
                            <p>{tableEntry.colorName}</p>
                          </td>
                          {tableEntry.sizes?.map((size, iy) => {
                            return (
                              <td
                                key={iy}
                                style={{ padding: 0, width: "70px" }}
                              >
                                <CInput
                                  type="number"
                                  value={emptyValueOnUndefined(size.amount)}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => onAmountChange(e, size.variantId)}
                                ></CInput>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                    {tableEntries.length === 0 ? (
                      <tr>
                        <td colSpan={tableColumns.length}>
                          No hay tamaños disponibles para esta prenda.
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
                <br />
                <br />
              </div>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={12}>
              <h5>Inventario Actual (Seleccione un inventario)</h5>
            </CCol>
          </CRow>
          <CRow>
            <CCol md={12}>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={inventoryProducts}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    totalAmount: (item: InventoryProduct) => {
                      return (
                        <td className="text-center">
                          <InventoryAsyncTooltip
                            productId={item.productId}
                            colorId={item.colorId}
                          >
                            <b>{item.totalAmount}</b>
                          </InventoryAsyncTooltip>
                        </td>
                      );
                    },
                    name: (item: InventoryProduct) => {
                      const nameClass: string =
                        item.hasMissingProducts !== undefined &&
                        item.hasMissingProducts === true
                          ? "text-warning"
                          : "";
                      return (
                        <td className="text-center">
                          <b className={nameClass}>{item.name}</b>
                        </td>
                      );
                    },
                  }}
                />
              </div>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting || loading ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {buttonText}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default ProductAmountForm;
