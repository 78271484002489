import StoreExpenseList from "../components/store-expense/StoreExpenseCrud";

const StoreExpenseListPage = () => {
  return (
    <>
      <StoreExpenseList />
    </>
  );
};

export default StoreExpenseListPage;
