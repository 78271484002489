import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { formatDateTime } from "./dates";
import { defaultValueOnUndefined } from "./fields";

import StoreExpense, {
  getStoreExpenseStatusLabel,
  getStoreExpenseTypeLabel,
} from "../models/store-expense";

const lungorLogo = require("../assets/img/lungorlogo.jpg");

interface ExpensesPDFEntry {
  expenseDate: string;
  storeName: string;
  userName: string;
  expenseType: string;
  description: string;
  price: string;
  status: string;
  revertDescription: string;
  revertDate: string;
  revertUserName: string;
}

const convertStoreExpensesToPDFEntries = (
  storeExpenses: StoreExpense[]
): ExpensesPDFEntry[] => {
  return storeExpenses.map((expense) => {
    let price = expense.price ? expense.price.toLocaleString() : "-";
    if (expense.reverted) {
      price = `(-${price})`;
    }
    return {
      expenseDate: expense.expenseDate
        ? formatDateTime(new Date(expense.expenseDate))
        : "-",
      storeName: defaultValueOnUndefined("-", expense.storeName),
      userName: defaultValueOnUndefined("-", expense.userName),
      expenseType: expense.expenseType
        ? getStoreExpenseTypeLabel(expense.expenseType)
        : "-",
      description: defaultValueOnUndefined("-", expense.description),
      price: price,
      status: expense.status ? getStoreExpenseStatusLabel(expense.status) : "-",
      revertDescription: defaultValueOnUndefined(
        "-",
        expense.revertDescription
      ),
      revertDate: expense.revertDate
        ? formatDateTime(new Date(expense.revertDate))
        : "-",
      revertUserName: defaultValueOnUndefined("-", expense.revertUserName),
    };
  });
};

type FontStyle = "normal" | "bold" | "italic";

const generateStoreExpensesReportPDF = async (
  storeExpenses: StoreExpense[],
  titlesMiddle: string[],
  titlesRight: string[]
) => {
  const doc = new jsPDF({
    orientation: "portrait",
    format: "a4",
  });
  const entries = convertStoreExpensesToPDFEntries(storeExpenses);

  const totals = calculateTotals(storeExpenses);

  const entriesPerPage = 30;
  const totalPages = Math.ceil(entries.length / entriesPerPage);

  const headerStyles = {
    fontSize: 5,
    font: "helvetica",
    fontStyle: "bold" as FontStyle,
    fillColor: "#ffffff",
    textColor: "#000000",
    lineWidth: 0.1,
  };

  const bodyStyles = {
    fontSize: 5,
    font: "helvetica",
  };

  for (let currentPage = 1; currentPage <= totalPages; currentPage++) {
    if (currentPage > 1) {
      doc.addPage();
    }

    doc.setFontSize(10);
    generateHeader(doc, titlesMiddle, titlesRight);

    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = Math.min(
      startIndex + entriesPerPage,
      storeExpenses.length
    );
    const pageEntries = entries.slice(startIndex, endIndex);

    if (currentPage === totalPages) {
      pageEntries.push(totals);
    }

    autoTable(doc, {
      theme: "grid",
      startY: 45,
      head: [
        [
          "Fecha",
          "Sucursal",
          "Usuario",
          "Tipo",
          "Descripción",
          "Monto (Gs)",
          "Estado",
          "Motivo Reversión",
          "Fecha Reversión",
          "Usuario Reversión",
        ],
      ],
      body: pageEntries.map((entry) => Object.values(entry)),
      headStyles: headerStyles,
      bodyStyles: bodyStyles,
    });

    doc.setFontSize(5);
    doc.text(
      `Página ${currentPage} de ${totalPages}`,
      14,
      doc.internal.pageSize.height - 10
    );
  }

  const blob = doc.output("blob");
  const url = URL.createObjectURL(blob);
  window.open(url, "_blank");
};

const calculateTotals = (storeExpenses: StoreExpense[]): ExpensesPDFEntry => {
  const priceTotal: number = storeExpenses.reduce(
    (sum, item) => sum + (item.price || 0),
    0
  );

  return {
    expenseDate: "",
    storeName: "",
    userName: "",
    expenseType: "",
    description: "",
    price: priceTotal.toLocaleString(),
    status: "",
    revertDescription: "",
    revertDate: "",
    revertUserName: "",
  };
};

const generateHeader = (
  doc: jsPDF,
  titlesMiddle: string[],
  titlesRight: string[]
) => {
  const x = 14;
  const y = 10;
  const width = doc.internal.pageSize.width - 28;
  const height = 30;
  const middleX = x + width / 2;

  doc.rect(x, y, width, height);

  const img = new Image();
  img.src = lungorLogo;
  doc.addImage(img, "JPEG", x + 5, y + 5, 35, 20);

  const totalTitlesMiddle = titlesMiddle.length;
  const totalTitlesRight = titlesRight.length;
  const totalTitles = Math.max(totalTitlesMiddle, totalTitlesRight);

  const verticalSpace = height / (totalTitles + 1);

  let middleTitleY = y + verticalSpace;

  titlesMiddle.forEach((title) => {
    const middleTitleWidth = doc.getTextWidth(title);
    const middleTitleX = middleX - middleTitleWidth / 2;
    doc.text(title, middleTitleX, middleTitleY);
    middleTitleY += verticalSpace;
  });

  doc.setFontSize(6);
  let rightTitleY = y + verticalSpace;

  const rightX = x + width;
  titlesRight.forEach((title) => {
    const rightTitleWidth = doc.getTextWidth(title);
    const rightTitleX = rightX - rightTitleWidth - 5;
    doc.text(title, rightTitleX, rightTitleY);
    rightTitleY += verticalSpace;
  });
};
export default generateStoreExpensesReportPDF;
